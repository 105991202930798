import { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../context/provider";

const useDataGovernance = () => {
  const [showErrorScreen, setShowErrorScreen] = useState(false);
  const [file, setFile] = useState({});

  const handleShowError = (show, file) => {
    setShowErrorScreen(show);
    setFile(file);
  };

  const {
    activeMenuState: { activeMenu },
  } = useContext(GlobalContext);
  const { verifyFileList } = useContext(GlobalContext);
  const [fileListArr, setFileListArr] = useState(verifyFileList);

  useEffect(() => {
    if (activeMenu === "all") {
      setFileListArr(verifyFileList);
      return;
    }
    setFileListArr(verifyFileList.filter((file) => file.id === activeMenu));
  }, [activeMenu, verifyFileList]);

  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const handleVerifyClose = () => setShowVerifyModal(false);
  const [filesData, setFilesData] = useState();

  const handleVerifyShow = (filesObj) => {
    setShowVerifyModal(true);
    setFilesData(filesObj);
  };

  return {
    showVerifyModal,
    handleVerifyShow,
    handleVerifyClose,
    fileListArr,
    filesData,
    showErrorScreen,
    handleShowError,
    file,
  };
};

export default useDataGovernance;
