import cx from "classnames";
import styles from "./Header.module.css";
import xanaduLogo from "../../assets/icons/logo-white.png";
import { Icon } from "..";
import useHeader from "./useHeader";

const Header = () => {
  const { handleLogout, handleUserProfile } = useHeader();

  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.logo)}>
        <img className={cx(styles.img)} alt="xanadu-logo" src={xanaduLogo} />
      </div>
      <div className={cx(styles.nav)}>
        {/* <div className={cx(styles.search)}>
          <Icon name="search_icon" />
          <input
            className={cx(styles.searchInput)}
            id="search"
            type="text"
            name="search"
            placeholder="Search"
            style={{
              border: "none",
              outline: "none",
              width: "250px",
              height: "41px",
              background: "none",
            }}
          />
          <Icon name="search_button" />
        </div> */}
        <div style={{ paddingLeft: "2rem" }} onClick={handleUserProfile}>
          <Icon name="profile" />
        </div>
        <div
          style={{ paddingLeft: "1rem" }}
          onClick={handleLogout}
          title="Logout"
        >
          <Icon name="logout" />
        </div>
      </div>
    </div>
  );
};

export default Header;
