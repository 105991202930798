import { useHistory } from "react-router-dom";

const useHeader = () => {
  const history = useHistory();

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    history.push("/login");
  };

  const handleUserProfile = (e) => {
    e.preventDefault();
    history.push("/user-profile");
  };

  return { handleLogout, handleUserProfile };
};

export default useHeader;
