import cx from "classnames";
import styles from "./ForgotPassword.module.css";
import "../../Form.css";
import { Carousel, Footer, Title, Icon, ErrorTooltip } from "../../components";
import useForgotPassword from "./useForgotPassword";

export const ForgotPassword = () => {
  const { handleChange, handleSubmit, form, errors, loading } =
    useForgotPassword();

  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.flexChild)}>
        <div className={cx(styles.content)}>
          <div className={cx(styles.outerContent)}>
            <Title title="Forgot Password" />

            <span className={cx(styles.infoSpan)}>
              Enter your verified username, we will send a password reset link.
            </span>
            <form onSubmit={handleSubmit}>
              <div className={cx(styles.form)}>
                <div className={cx(styles.inputForm)}>
                  {errors.username && (
                    <div className="error-icon">
                      <ErrorTooltip
                        title={errors.username}
                        placement="right"
                        interactive
                      >
                        <div>
                          <Icon name="error" />
                        </div>
                      </ErrorTooltip>
                    </div>
                  )}
                  <label htmlFor="username" className="form-label">
                    Username
                  </label>
                  <input
                    id="username"
                    type="text"
                    name="username"
                    className={cx("form-input", {
                      "form-input-error": errors.username,
                    })}
                    value={form.username}
                    onChange={handleChange}
                  />
                </div>

                <div className={cx(styles.action)}>
                  <button
                    disabled={loading}
                    className="form-input-btn"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
      <div className={cx(styles.flexChild)}>
        <Carousel />
      </div>
    </div>
  );
};

export default ForgotPassword;
