import cx from "classnames";
import styles from "./Footer.module.css";
// import leftIllustration from "../../assets/icons/left_illustration.svg";
import whitelabel from "../../assets/icons/whitelabel.svg";

const Footer = () => {
  return (
    <div className={cx(styles.container)}>
      {/* <img src={leftIllustration} alt="illustration" /> */}
      <div className={cx(styles.whitelabel)}>
        <span>
          <img src={whitelabel} alt="illustration" />
        </span>
        <p className={cx(styles.whitelabelText)}>
          Powered by{" "}
          <a
            className={cx(styles.hyperlinkColor)}
            href="https://fortunetimesgroup.com/"
          >
            FortuneTimes Group
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
