import { acpUdcCcPiPColDefsMap } from "./ft-acp-udc-cc-pi-p";
import { acpUdcCcPiSColDefsMap } from "./ft-acp-udc-cc-pi-s";
import { apHsiHsColDefsMap } from "./ft-ap-hsi-hs";
import { apHsiSColDefsMap } from "./ft-ap-hsi-s";
import { apSciColDefsMap } from "./ft-ap-sci";
import { apSgiColDefsMap } from "./ft-ap-sgi";
import { bdaLimColDefsMap } from "./ft-bda-lim";
import { bdaSapsColDefsMap } from "./ft-bda-saps";
import { ppPpsiColDefsMap } from "./ft-pp-ppsi";
import { sdSaiColDefsMap } from "./ft-sd-sai";
import { sdSiColDefsMap } from "./ft-sd-si";

export const colorCode = {
  red: "#FF7070",
  yellow: "#FFF388",
  blue: "#A7C0E9",
};

export const sanitiseData = (data) => {
  if (data === undefined || data === null) {
    return "";
  } else {
    return data.trim();
  }
};

export const isRegExMatch = (data, expr) => {
  if (data.match(expr)) {
    return true;
  } else {
    return false;
  }
};

export const isIntegerType = (data) => {
  let integerRegEx = /^\d+$/;
  return isRegExMatch(data, integerRegEx);
};

export const isTextType = (data) => {
  let textRegEx = /^[A-Za-z ]+$/;
  return isRegExMatch(data, textRegEx);
};

export const isOnlyTextType = (data) => {
  let onlyTextRegEx = /^[A-Za-z]+$/;
  return isRegExMatch(data, onlyTextRegEx);
};

export const completenessChk = (data) => {
  // data completeness check
  const emptyValueList = ["", "none", "n/a"];
  if (emptyValueList.includes(data.toLowerCase())) {
    return { "background-color": colorCode.red };
  }
};

export const typeConformityChk = (data, type) => {
  let chkFailed = false;

  if (type === "text") {
    if (!isTextType(data)) {
      chkFailed = true;
    }
  } else if (type === "integer") {
    if (!isIntegerType(data)) {
      chkFailed = true;
    }
  }

  if (chkFailed) {
    return { "background-color": colorCode.yellow };
  }
};

export const columnDefsMap = {
  "acp-udc-cc-pi-p": acpUdcCcPiPColDefsMap,
  "acp-udc-cc-pi-s": acpUdcCcPiSColDefsMap,
  "sd-si": sdSiColDefsMap,
  "sd-sai": sdSaiColDefsMap,
  "ap-hsi-hs": apHsiHsColDefsMap,
  "ap-hsi-s": apHsiSColDefsMap,
  "ap-sgi": apSgiColDefsMap,
  "ap-sci": apSciColDefsMap,
  "pp-ppsi": ppPpsiColDefsMap,
  "bda-saps": bdaSapsColDefsMap,
  "bda-lim": bdaLimColDefsMap,
};

// export const isCsvError = (rowData, type) => {
//   let isError = false;

//   for (let row of rowData) {
//     for (let key in row) {
//       let styleVal = null;
//       let colObj = columnDefsMap[type][key];

//       if (colObj) {
//         styleVal = colObj["cellStyle"]({ value: row[key] });
//       } else {
//         // Note. This else block runs when a column in file is not defined on app
//         styleVal = true;
//       }

//       if (styleVal) {
//         isError = true;
//         break;
//       }
//     }
//     if (isError) {
//       break;
//     }
//   }
//   if (isError) {
//     return true;
//   } else {
//     return false;
//   }
// };

export const isCsvError = (rowData, type) => {
  let chkRowData = [];

  for (let row of rowData) {
    for (let key in row) {
      let styleVal = null;
      let colObj = columnDefsMap[type][key];

      if (colObj) {
        styleVal = colObj["cellStyle"]({ value: row[key] });
      } else {
        // Note. This else block runs when a column in file is not defined on app
        return { chkRowData: null, isFormatIncorrect: true };
      }

      if (styleVal) {
        chkRowData.push(row);
        break;
      }
    }
  }
  return { chkRowData: chkRowData, isFormatIncorrect: false };
};
