import { useState, useEffect, useReducer } from "react";
import { loginApi } from "../../context/actions";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { RESET } from "../../constants/actionTypes";
import validate from "./validateLogin";
import { loginReducer } from "../../context/reducer";
import { loginInitialState } from "../../context/initialState";

const useLogin = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [form, setValues] = useState({
    username: "",
    password: "",
    captchaToken: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(form));
    setIsSubmitted(true);
  };

  const verifyCallback = (response) => {
    if (response) {
      setIsVerified(true);
      setValues((prevState) => ({
        ...prevState,
        captchaToken: response,
      }));
    }
  };

  const expiredCallback = () => {
    setIsVerified(false);
    setValues((prevState) => ({
      ...prevState,
      captchaToken: "",
    }));
  };

  const [{ payload, loading }, dispatch] = useReducer(
    loginReducer,
    loginInitialState
  );

  useEffect(() => {
    // if (Object.keys(errors).length === 0 && isSubmitted && isVerified) {
    if (Object.keys(errors).length === 0 && isSubmitted) {
      loginApi(form)(dispatch);
    }
  }, [errors]);

  useEffect(() => {
    if (payload) {
      if (payload.success) {
        localStorage.email = payload.data.user.email;
        localStorage.mobileNo = payload.data.user.mobile_no;
        history.push("/validate");
      }
      addToast(payload.msg, {
        appearance: payload.success ? "success" : "error",
        autoDismiss: true,
      });
    }
    return () => {
      dispatch({
        type: RESET,
      });
    };
  }, [payload]);

  return {
    captchaSiteKey,
    handleChange,
    handleSubmit,
    verifyCallback,
    expiredCallback,
    form,
    errors,
    loading,
  };
};

export default useLogin;
