import {
  REG_ERROR,
  REG_LOADING,
  REG_SUCCESS,
  RESET,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SEND_EMAIL_LOADING,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_ERROR,
  SEND_OTP_LOADING,
  SEND_OTP_SUCCESS,
  SEND_OTP_ERROR,
  VERIFY_EMAIL_LOADING,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR,
  VERIFY_OTP_LOADING,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
  UPLOAD_LOADING,
  UPLOAD_SUCCESS,
  UPLOAD_ERROR,
  GRID_ERROR,
  SET_FILE_STATUS,
  GRID_INCORRECT,
  VERIFY_DB_LOADING,
  VERIFY_DB_SUCCESS,
  VERIFY_DB_ERROR,
  VERIFY_DB_GRID_ERROR,
  VERIFY_DB_NO_CHECK,
  UPLOAD_HISTORY_LOADING,
  UPLOAD_HISTORY_SUCCESS,
  UPLOAD_HISTORY_ERROR,
  REPORT_SELECT_CHANGE,
  USER_PROFILE_LOADING,
  USER_PROFILE_SET,
  USER_PROFILE_UPDATE,
  USER_PROFILE_ERROR,
  USER_PROFILE_RESET,
} from "../constants/actionTypes";
import {
  regInitialState,
  loginInitialState,
  forgotPasswordInitialState,
  resetPasswordInitialState,
  sendEmailInitialState,
  sendOtpInitialState,
  verifyEmailInitialState,
  verifyOtpInitialState,
} from "../context/initialState";

export const registerReducer = (state, { type, payload }) => {
  switch (type) {
    case REG_LOADING:
      return {
        ...state,
        loading: true,
      };
    case REG_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case REG_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case RESET:
      return regInitialState;
    default:
      return state;
  }
};

export const loginReducer = (state, { type, payload }) => {
  switch (type) {
    case LOGIN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case RESET:
      return loginInitialState;
    default:
      return state;
  }
};

export const forgotPasswordReducer = (state, { type, payload }) => {
  switch (type) {
    case FORGOT_PASSWORD_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case RESET:
      return forgotPasswordInitialState;
    default:
      return state;
  }
};
export const resetPasswordReducer = (state, { type, payload }) => {
  switch (type) {
    case RESET_PASSWORD_LOADING:
      return {
        ...state,
        loading: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case RESET:
      return resetPasswordInitialState;
    default:
      return state;
  }
};

export const sendOtpReducer = (state, { type, payload }) => {
  switch (type) {
    case SEND_OTP_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SEND_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case SEND_OTP_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case RESET:
      return sendOtpInitialState;
    default:
      return state;
  }
};

export const sendEmailReducer = (state, { type, payload }) => {
  switch (type) {
    case SEND_EMAIL_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case SEND_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case RESET:
      return sendEmailInitialState;
    default:
      return state;
  }
};

export const verifyOtpReducer = (state, { type, payload }) => {
  switch (type) {
    case VERIFY_OTP_LOADING:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case VERIFY_OTP_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case RESET:
      return verifyOtpInitialState;
    default:
      return state;
  }
};

export const verifyEmailReducer = (state, { type, payload }) => {
  switch (type) {
    case VERIFY_EMAIL_LOADING:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case VERIFY_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case RESET:
      return verifyEmailInitialState;
    default:
      return state;
  }
};

export const activeMenuReducer = (state, { type }) => {
  return {
    ...state,
    activeMenu: type,
  };
};

export const fileListReducer = (
  state,
  { type, fileId, gridError, fileStatus }
) => {
  switch (type) {
    case UPLOAD_LOADING:
      return state.map((values) => {
        return {
          ...values,
          files: values.files.map((file) => {
            if (file.id === fileId) {
              return { ...file, state: "in_progress", error: {} };
            }
            return file;
          }),
        };
      });
    case UPLOAD_SUCCESS:
      return state.map((values) => {
        return {
          ...values,
          files: values.files.map((file) => {
            if (file.id === fileId) {
              return { ...file, state: "accepted", error: {} };
            }
            return file;
          }),
        };
      });
    case UPLOAD_ERROR:
      return state.map((values) => {
        return {
          ...values,
          files: values.files.map((file) => {
            if (file.id === fileId) {
              return { ...file, state: "initial" };
            }
            return file;
          }),
        };
      });
    case GRID_ERROR:
      return state.map((values) => {
        return {
          ...values,
          files: values.files.map((file) => {
            if (file.id === fileId) {
              return {
                ...file,
                state: "error",
                error: { ...file.error, ...gridError },
              };
            }
            return file;
          }),
        };
      });
    case GRID_INCORRECT:
      return state.map((values) => {
        return {
          ...values,
          files: values.files.map((file) => {
            if (file.id === fileId) {
              return {
                ...file,
                state: "incorrect",
                error: { ...file.error, ...gridError },
              };
            }
            return file;
          }),
        };
      });
    case SET_FILE_STATUS:
      return state.map((values) => {
        return {
          ...values,
          files: values.files.map((file) => {
            const filteredFile = fileStatus.find((f) => {
              return f.file_type === file.id;
            });
            return {
              ...file,
              state: filteredFile.status,
            };
          }),
        };
      });
    default:
      return state;
  }
};

export const verifyFileListReducer = (
  state,
  { type, fileId, gridError, fileStatus }
) => {
  switch (type) {
    case VERIFY_DB_LOADING:
      return state.map((values) => {
        return {
          ...values,
          files: values.files.map((file) => {
            if (file.id === fileId) {
              return { ...file, state: "in_progress", error: {} };
            }
            return file;
          }),
        };
      });
    case VERIFY_DB_SUCCESS:
      return state.map((values) => {
        return {
          ...values,
          files: values.files.map((file) => {
            if (file.id === fileId) {
              return { ...file, state: "accepted", error: {} };
            }
            return file;
          }),
        };
      });
    case VERIFY_DB_NO_CHECK:
      return state.map((values) => {
        return {
          ...values,
          files: values.files.map((file) => {
            if (file.id === fileId) {
              return { ...file, state: "noCheck", error: {} };
            }
            return file;
          }),
        };
      });
    case VERIFY_DB_ERROR:
      return state.map((values) => {
        return {
          ...values,
          files: values.files.map((file) => {
            if (file.id === fileId) {
              return { ...file, state: "error" };
            }
            return file;
          }),
        };
      });
    case VERIFY_DB_GRID_ERROR:
      return state.map((values) => {
        return {
          ...values,
          files: values.files.map((file) => {
            if (file.id === fileId) {
              return {
                ...file,
                state: "error",
                error: { ...file.error, ...gridError },
              };
            }
            return file;
          }),
        };
      });
    case SET_FILE_STATUS:
      return state.map((values) => {
        return {
          ...values,
          files: values.files.map((file) => {
            const filteredFile = fileStatus.find((f) => {
              return f.file_type === file.id;
            });
            return {
              ...file,
              state: filteredFile.status,
            };
          }),
        };
      });
    default:
      return state;
  }
};

export const reportListReducer = (state, { type, reportType, key, value }) => {
  switch (type) {
    case REPORT_SELECT_CHANGE:
      return state.map((reportObj) => {
        if (reportObj.type === reportType) {
          if (key === "from_semester") {
            reportObj.from.semester = value;
          } else if (key === "from_year") {
            reportObj.from.year = value;
          } else if (key === "to_semester") {
            reportObj.to.semester = value;
          } else if (key === "to_year") {
            reportObj.to.year = value;
          } else if (key === "format") {
            reportObj.format = value;
          }
        }
        return reportObj;
      });
    default:
      return state;
  }
};

export const userProfileReducer = (state, { type, payload }) => {
  switch (type) {
    case USER_PROFILE_RESET:
      return {
        ...state,
        payload: null,
      };
    case USER_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case USER_PROFILE_SET:
      return {
        ...state,
        formValue: payload,
        loading: false,
      };
    case USER_PROFILE_UPDATE:
      return { ...state, loading: false, payload };
    case USER_PROFILE_ERROR:
      return {
        ...state,
        payload: payload,
        loading: false,
      };

    default:
      return state;
  }
};

export const fileHistoryListReducer = (state, { type, fileId, payload }) => {
  switch (type) {
    case UPLOAD_HISTORY_LOADING:
      return state.map((values) => {
        return {
          ...values,
          files: values.files.map((file) => {
            if (file.id === fileId) {
              return { ...file, state: "in_progress" };
            }
            return file;
          }),
        };
      });
    case UPLOAD_HISTORY_SUCCESS:
      return state.map((values) => {
        return {
          ...values,
          files: values.files.map((file) => {
            if (file.id === fileId) {
              if (payload.length > 0) {
                return { ...file, state: "initial", historyList: payload };
              } else {
                return { ...file, state: "no_data", historyList: [] };
              }
            }
            return file;
          }),
        };
      });
    case UPLOAD_HISTORY_ERROR:
      return state.map((values) => {
        return {
          ...values,
          files: values.files.map((file) => {
            if (file.id === fileId) {
              return {
                ...file,
                state: "error",
                // error: { ...file.error, ...gridError },
              };
            }
            return file;
          }),
        };
      });
    default:
      return state;
  }
};
