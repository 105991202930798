export const GLOBAL_CONST = {
  MIN_YEAR: 2012,
};

export const DATA_SUBMIT_CONST = {};

export const formatListInitialState = ["csv", "pdf"];
export const semesterListInitialState = ["Fall", "Spring", "Summer"];

export const yearListInitialState = [];
const currentYear = new Date().getFullYear();
const startYearFrom = GLOBAL_CONST.MIN_YEAR;
for (let yr = currentYear; yr >= startYearFrom; yr--) {
  yearListInitialState.push(yr);
}
