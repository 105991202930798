import { GLOBAL_CONST } from "../../assets/constants";
import {
  completenessChk,
  isRegExMatch,
  sanitiseData,
  typeConformityChk,
  colorCode,
} from "./common";
import { programNameList, stateCodeList } from "./constants";

export const global_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }
};

export const program_id_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChk(params.value)) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let programIdRegEx = /^\d{3}$/;
  if (!isRegExMatch(params.value, programIdRegEx)) {
    return { "background-color": colorCode.yellow };
  }

  if (params.value < 100 || params.value > 999) {
    return { "background-color": colorCode.yellow };
  }
};

export const program_name_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  if (!programNameList.includes(params.value)) {
    return { "background-color": colorCode.yellow };
  }
};

export const course_code_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }
};

export const course_name_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let strSplitList = params.value.split(" ");
  strSplitList.forEach((ele) => {
    let firstChar = ele[0];
    if (firstChar.match(/[a-zA-Z]/)) {
      if (firstChar !== firstChar.toUpperCase()) {
        return { "background-color": colorCode.yellow };
      }
    }
  });
};

export const student_id_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let studentIdRegex = /^\d{4}$/;
  if (!isRegExMatch(params.value, studentIdRegex)) {
    return { "background-color": colorCode.yellow };
  }

  if (params.value === "0000") {
    return { "background-color": colorCode.yellow };
  }
};

export const student_applicant_id_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let studentIdRegex = /^\d{5}$/;
  if (!isRegExMatch(params.value, studentIdRegex)) {
    return { "background-color": colorCode.yellow };
  }

  if (params.value < 10000 || params.value > 99999) {
    return { "background-color": colorCode.yellow };
  }
};

export const bool_val_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  if (!(params.value === "0" || params.value === "1")) {
    return { "background-color": colorCode.yellow };
  }
};

export const name_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  if (params.value[0] !== params.value[0].toUpperCase()) {
    return { "background-color": colorCode.yellow };
  }
};

export const high_school_id_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let typeChkRes = typeConformityChk(params.value, "integer");
  if (typeChkRes) {
    return typeChkRes;
  }

  if (params.value < 1000 || params.value > 9999) {
    return { "background-color": colorCode.yellow };
  }
};

export const school_name_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let strSplitList = params.value.split(" ");
  strSplitList.forEach((ele) => {
    let firstChar = ele[0];
    if (firstChar.match(/[a-zA-Z]/)) {
      if (firstChar !== firstChar.toUpperCase()) {
        return { "background-color": colorCode.yellow };
      }
    }
  });
};

export const school_district_name_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let strSplitList = params.value.split(" ");
  strSplitList.forEach((ele) => {
    let firstChar = ele[0];
    if (firstChar.match(/[a-zA-Z]/)) {
      if (firstChar !== firstChar.toUpperCase()) {
        return { "background-color": colorCode.yellow };
      }
    }
  });
};

export const ward_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let schoolType = params.value.split(" ");
  if (schoolType.length !== 2) {
    return { "background-color": colorCode.yellow };
  }
  let numbers = /^\d+$/;
  if (schoolType[0] !== "Ward" || !schoolType[1].match(numbers)) {
    return { "background-color": colorCode.yellow };
  }
};

export const city_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let strSplitList = params.value.split(" ");
  strSplitList.forEach((ele) => {
    let firstChar = ele[0];
    if (firstChar.match(/[a-zA-Z]/)) {
      if (firstChar !== firstChar.toUpperCase()) {
        return { "background-color": colorCode.yellow };
      }
    }
  });
};

export const state_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  if (!stateCodeList.includes(params.value)) {
    return { "background-color": colorCode.yellow };
  }
};

export const school_type_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let integerRegEx = /^[1-5]$/;
  if (!params.value.match(integerRegEx)) {
    return { "background-color": colorCode.yellow };
  }
};

export const semester_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let words = params.value.split(" ");
  if (words.length !== 2 || words[1].length !== 4) {
    return { "background-color": colorCode.yellow };
  }

  const semesterList = ["Spring", "Fall", "Summer"];
  if (!semesterList.includes(words[0])) {
    return { "background-color": colorCode.yellow };
  }

  // check year
  let yearRegex = /^\d{4}$/;
  if (!words[1].match(yearRegex) || words[1] < GLOBAL_CONST.MIN_YEAR) {
    return { "background-color": colorCode.yellow };
  }
};

export const high_school_gpa_chk = (params, from, to) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let floatRegex = /^\d{1}\.\d{1,2}$/;
  if (!params.value.match(floatRegex)) {
    return { "background-color": colorCode.yellow };
  }

  let floatValue = parseFloat(params.value);
  if (floatValue < from || floatValue > to) {
    return { "background-color": colorCode.yellow };
  }
};

export const course_credit_chk = (params, from, to) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let floatRegex = /^\d{1}\.\d{1}$/;
  let integerRegEx = /^\d{1}$/;
  if (!(params.value.match(floatRegex) || params.value.match(integerRegEx))) {
    return { "background-color": colorCode.yellow };
  }

  let floatValue = parseFloat(params.value);
  if (floatValue < from || floatValue > to || floatValue % 0.5) {
    return { "background-color": colorCode.yellow };
  }
};

export const lgpa_hours_attempted_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let floatRegex = /^\d{1,2}\.\d{1}$/;
  let integerRegEx = /^\d{1,2}$/;
  if (!(params.value.match(floatRegex) || params.value.match(integerRegEx))) {
    return { "background-color": colorCode.yellow };
  }

  let floatValue = parseFloat(params.value);
  if (floatValue <= 0 || floatValue % 0.5) {
    return { "background-color": colorCode.yellow };
  }
};

export const tgpa_hours_attempted_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let floatRegex = /^\d{1,3}\.\d{1,2}$/;
  let integerRegEx = /^\d+$/;
  if (!(params.value.match(floatRegex) || params.value.match(integerRegEx))) {
    return { "background-color": colorCode.yellow };
  }

  let floatValue = parseFloat(params.value);
  if (floatValue <= 0 || floatValue % 0.5) {
    return { "background-color": colorCode.yellow };
  }
};

export const ftic_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  if (!(params.value === "0" || params.value === "1")) {
    return { "background-color": colorCode.yellow };
  }
};

export const course_grade_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  const gradeList = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "A+",
    "B+",
    "C+",
    "D+",
    "E+",
    "F+",
    "A-",
    "B-",
    "C-",
    "D-",
    "E-",
    "F-",
  ];
  if (!gradeList.includes(params.value)) {
    return { "background-color": colorCode.yellow };
  }
};

export const career_clusters_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let careerClustersList = [
    "Agriculture, Food & Natural Resources",
    "Architecture & Construction",
    "Arts, A/V Technology & Communications",
    "Business, Management & Administration",
    "Education & Training",
    "Finance",
    "Government & Public Administration",
    "Health Science",
    "Hospitality & Tourism",
    "Human Services",
    "Information Technology",
    "Law, Public Safety, Corrections & Security",
    "Manufacturing",
    "Marketing",
    "Science, Technology, Engineering & Mathematics",
    "Transportation, Distribution & Logistics",
  ];
  if (!careerClustersList.includes(params.value)) {
    return { "background-color": colorCode.yellow };
  }
};

export const unemployed_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  if (!(params.value === "0" || params.value === "1")) {
    return { "background-color": colorCode.yellow };
  }
};

export const pos_program_id_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let programIdRegEx = /^\d{2}$/;
  if (!isRegExMatch(params.value, programIdRegEx)) {
    return { "background-color": colorCode.yellow };
  }

  if (params.value < 10 || params.value > 99) {
    return { "background-color": colorCode.yellow };
  }
};

export const pos_program_name_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let posProgramNameList = [
    "Energy and Natural Resource Technology",
    "Horticulture Science",
    "Architectural Design",
    "Construction Management",
    "Electrical Technology",
    "Heating, Ventilation, Air Conditioning, and Refrigeration (HVAC-R)",
    "Plumbing Technology",
    "Carpentry",
    "Audio/Video Production",
    "Animation",
    "Graphic Design and Illustration",
    "Business Management",
    "Business Information Management",
    "Child Care and Development",
    "Teacher Education and Training",
    "Accounting",
    "Finance",
    "Public Management and Administration",
    "Foreign Service and Diplomacy",
    "Biomedical Medicine (PLTW)",
    "Biotechnology",
    "Health Science: Certified Nursing Assistant",
    "Health Science: General",
    "Culinary Arts",
    "Hospitality Services",
    "Cosmetology",
    "Barbering",
    "Digital Media",
    "Computer Maintenance Technician",
    "Networking",
    "Computer Science (PLTW)",
    "Computer Science (AP)",
    "Law Enforcement",
    "Welding",
    "Marketing",
    "Aerospace Engineering (PLTW)",
    "Civil Engineering and Architecture (PLTW)",
    "Computer Integrated Manufacturing (PLTW)",
    "Computer Science and Software Engineering (PLTW)",
    "Digital Electronics (PLTW)",
    "Environmental Sustainability (PLTW)",
    "Automotive Technology",
  ];
  if (!posProgramNameList.includes(params.value)) {
    return { "background-color": colorCode.yellow };
  }
};

export const cluster_id_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let clusterIdRegex = /^\d{1,2}$/;
  if (!params.value.match(clusterIdRegex)) {
    return { "background-color": colorCode.yellow };
  }
};

export const cluster_name_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let clusterNameList = [
    "Agriculture, Food & Natural Resources",
    "Architecture & Construction",
    "Arts, A/V Technology & Communications",
    "Business, Management & Administration",
    "Education & Training",
    "Finance",
    "Government & Public Administration",
    "Health Science",
    "Hospitality & Tourism",
    "Human Services",
    "Information Technology",
    "Law, Public Safety, Corrections & Security",
    "Manufacturing",
    "Marketing",
    "Science, Technology, Engineering & Mathematics",
    "Transportation, Distribution & Logistics",
  ];
  if (!clusterNameList.includes(params.value)) {
    return { "background-color": colorCode.yellow };
  }
};

export const standard_occupational_code_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let codeRegex = /^\d{2}-\d{4}$/;
  if (!params.value.match(codeRegex)) {
    return { "background-color": colorCode.yellow };
  }
};

export const annual_openings_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let typeChkRes = typeConformityChk(params.value, "integer");
  if (typeChkRes) {
    return typeChkRes;
  }
};
