import {
  annual_openings_chk,
  global_chk,
  standard_occupational_code_chk,
} from "./field-chk-methods";

export const bdaLimColDefsMap = {
  Standard_Occupational_Code: {
    headerName: "Standard_Occupational_Code",
    cellStyle: (params) => standard_occupational_code_chk(params),
  },
  Career_Clusters: {
    headerName: "Career_Clusters",
    cellStyle: (params) => global_chk(params),
  },
  Percentile_25th: {
    headerName: "Percentile_25th",
    cellStyle: (params) => global_chk(params),
  },
  Percentile_50th: {
    headerName: "Percentile_50th",
    cellStyle: (params) => global_chk(params),
  },
  Percentile_75th: {
    headerName: "Percentile_75th",
    cellStyle: (params) => global_chk(params),
  },
  Annual_Openings: {
    headerName: "Annual_Openings",
    cellStyle: (params) => annual_openings_chk(params),
  },
};
