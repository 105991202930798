import { Suspense } from "react";
import cx from "classnames";
import styles from "./App.module.css";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
} from "react-router-dom";
import routes from "./routes/routes";
import isAuthenticated from "./utils/isAuthenticated";
import { GlobalProvider } from "./context/provider";
import { ToastProvider } from "react-toast-notifications";

const RenderRoute = (route) => {
  document.title = route.title;
  const history = useHistory();
  if (route.needsAuth) {
    if (!isAuthenticated()) {
      history.push("/login");
    }
  } else {
    if (isAuthenticated()) {
      history.push("/");
    }
  }

  return <Route exact path={route.path} component={route.component}></Route>;
};

const App = () => {
  return (
    <GlobalProvider>
      <ToastProvider>
        <div className={cx(styles.container)}>
          <Router>
            <Suspense fallback={<div></div>}>
              <Switch>
                {routes.map((route, index) => (
                  <RenderRoute {...route} key={index} />
                ))}
              </Switch>
            </Suspense>
          </Router>
        </div>
      </ToastProvider>
    </GlobalProvider>
  );
};

export default App;
