export default function validateRegister(values) {
  let errors = {};

  if (!values.username.trim()) {
    errors.username = "Username is required.";
  }

  if (!values.email.trim()) {
    errors.email = "Email is required.";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email is required.";
  }

  if (!values.mobile.trim()) {
    errors.mobile = "Mobile number is required.";
  } else if (
    !/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(values.mobile)
  ) {
    errors.mobile = "Mobile number is required.";
  }

  if (!values.password.trim()) {
    errors.password = "Password is required.";
  } else if (values.password.length < 8) {
    errors.password = "Password must be 8 characters or more.";
  }

  if (!values.confPassword.trim()) {
    errors.confPassword = "Password is required.";
  } else if (values.confPassword !== values.password) {
    errors.confPassword = "Passwords do not match.";
  }

  return errors;
}
