import cx from "classnames";
import styles from "./UserProfile.module.css";
import Header from "../../components/Header/Header";
import useUserProfile from "./useUserProfile";
import Icon from "../../components/Icon/Icon";
import { Title } from "../../components";
import makeChangesIcon from "../../assets/icons/pencil.png";

export const UserProfile = () => {
  const {
    form,
    loader,
    makeChangeFlag,
    handleBackClick,
    handleChange,
    handleMakeChanges,
    handleSubmit,
  } = useUserProfile();

  return (
    <div>
      <Header />
      <div className={cx(styles.container)}>
        <div className={cx(styles.content)}>
          <div className={cx(styles.innerContent)}>
            <Title title="User" subTitle="Profile" />
            <span className={cx(styles.back)} onClick={handleBackClick}>
              <Icon name="back" />
            </span>

            <form>
              <div>
                <div className={cx(styles.form)}>
                  <div className={cx(styles.formInput)}>
                    <label htmlFor="username" className={styles.formInputLabel}>
                      Username
                    </label>
                    <br />
                    <input
                      id="username"
                      type="text"
                      name="username"
                      className={styles.formInputField}
                      value={form.username.value}
                      onChange={handleChange}
                      disabled={true}
                    />
                  </div>

                  <div className={cx(styles.formInput)}>
                    <label htmlFor="email" className={styles.formInputLabel}>
                      E-mail
                    </label>
                    <br />
                    <input
                      id="email"
                      type="text"
                      name="email"
                      className={styles.formInputField}
                      value={form.email.value}
                      onChange={handleChange}
                      disabled={!makeChangeFlag}
                    />
                  </div>

                  <div className={cx(styles.formInput)}>
                    <label
                      htmlFor="mobileNumber"
                      className={styles.formInputLabel}
                    >
                      Mobile number
                    </label>
                    <br />
                    <input
                      id="mobileNumber"
                      type="text"
                      name="mobileNumber"
                      className={styles.formInputField}
                      value={form.mobileNumber.value}
                      onChange={handleChange}
                      disabled={!makeChangeFlag}
                    />
                  </div>

                  <div className={cx(styles.formInput)}>
                    <label htmlFor="password" className={styles.formInputLabel}>
                      Password
                    </label>
                    <br />
                    <input
                      id="password"
                      type="password"
                      name="password"
                      className={styles.formInputField}
                      value={form.password.value}
                      onChange={handleChange}
                      disabled={!makeChangeFlag}
                    />
                  </div>

                  {makeChangeFlag ? (
                    <button
                      disabled={loader}
                      className={cx(styles.makeChangesButton)}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      disabled={loader}
                      className={cx(styles.makeChangesButton)}
                      type="submit"
                      onClick={handleMakeChanges}
                    >
                      <img
                        className={cx(styles.makeChangesIcon)}
                        alt="make-changes"
                        src={makeChangesIcon}
                      />{" "}
                      Make Changes
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
