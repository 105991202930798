const Icon = ({ name }) => {
  return (
    <div style={{ cursor: "pointer", lineHeight: 0 }}>
      {(function () {
        switch (name) {
          case "download_hist":
            return (
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10.5" cy="10.5" r="10" stroke="#606060" />
                <path
                  d="M10.5 5.59961L10.5 11.8996"
                  stroke="#606060"
                  strokeLinecap="round"
                />
                <path
                  d="M6.30005 11.2002V14.0002H14.7V11.2002"
                  stroke="#606060"
                  strokeLinecap="round"
                />
                <path
                  d="M8.39844 9.7998L10.4984 11.8998L12.5984 9.7998"
                  stroke="#606060"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
          case "back":
            return (
              <svg
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="19"
                  cy="19"
                  r="18.25"
                  stroke="#5E9FFF"
                  strokeWidth="1.5"
                />
                <path
                  d="M18.2402 13.6801L12.1602 19.6495L18.2402 25.8401"
                  stroke="#5E9FFF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.6802 19.76H27.3602"
                  stroke="#5E9FFF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            );
          case "error":
            return (
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="7" cy="7" r="7" fill="#FF7070" />
                <rect
                  x="6.41663"
                  y="3.5"
                  width="1.16667"
                  height="4.66667"
                  rx="0.583333"
                  fill="white"
                />
                <rect
                  x="6.41663"
                  y="9.33337"
                  width="1.16667"
                  height="1.16667"
                  rx="0.583333"
                  fill="white"
                />
              </svg>
            );
          case "search_icon":
            return (
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.91294 12.7692C10.1786 12.7692 12.8259 10.1346 12.8259 6.88458C12.8259 3.63462 10.1786 1 6.91294 1C3.64731 1 1 3.63462 1 6.88458C1 10.1346 3.64731 12.7692 6.91294 12.7692Z"
                  stroke="#FFFFFF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.0938 11.0449L18.0001 17.9181"
                  stroke="#FFFFFF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
          case "search_button":
            return (
              <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  r="10.75"
                  transform="matrix(-1 0 0 1 11.5 11.5)"
                  stroke="#FFFFFF"
                  strokeWidth="1.5"
                />
                <path
                  d="M11.96 8.28027L15.64 11.8934L11.96 15.6403"
                  stroke="#FFFFFF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.7198 11.96H6.43985"
                  stroke="#FFFFFF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            );
          case "profile":
            return (
              <svg
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="16.5" cy="16.5" r="16.5" fill="#F9424E" />
                <path
                  d="M23 24.242V22.7789C23 22.0028 22.6576 21.2584 22.0481 20.7096C21.4386 20.1608 20.612 19.8525 19.75 19.8525H13.25C12.388 19.8525 11.5614 20.1608 10.9519 20.7096C10.3424 21.2584 10 22.0028 10 22.7789V24.242"
                  stroke="url(#paint0_linear)"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.5 15C18.433 15 20 13.433 20 11.5C20 9.567 18.433 8 16.5 8C14.567 8 13 9.567 13 11.5C13 13.433 14.567 15 16.5 15Z"
                  stroke="url(#paint1_linear)"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="16.5"
                    y1="19.8525"
                    x2="16.5"
                    y2="24.242"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear"
                    x1="16.5"
                    y1="8"
                    x2="16.5"
                    y2="15"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" />
                  </linearGradient>
                </defs>
              </svg>
            );
          case "logout":
            return (
              <svg
                width="21"
                height="19"
                viewBox="0 0 21 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.33337 17.8119H3.11112C2.55122 17.8119 2.01425 17.6166 1.61833 17.2691C1.22242 16.9215 1 16.4501 1 15.9586V2.98518C1 2.49364 1.22242 2.02223 1.61833 1.67467C2.01425 1.3271 2.55122 1.13184 3.11112 1.13184H7.33337"
                  stroke="#FFFFFF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.7223 14.1056L20 9.47221L14.7223 4.83887"
                  stroke="#FFFFFF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.9987 9.47168H7.33203"
                  stroke="#FFFFFF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
          case "eye":
            return (
              <svg
                width="21"
                height="12"
                viewBox="0 0 21 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.5001 8.50567C12.0304 8.50567 13.2709 7.34248 13.2709 5.90762C13.2709 4.47276 12.0304 3.30957 10.5001 3.30957C8.96979 3.30957 7.72925 4.47276 7.72925 5.90762C7.72925 7.34248 8.96979 8.50567 10.5001 8.50567Z"
                  stroke="#606060"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19.506 5.66448C18.5853 4.68464 14.9033 1.08301 10.5 1.08301C6.0968 1.08301 2.41475 4.68464 1.49405 5.66448C1.36461 5.80411 1.36461 6.0118 1.49405 6.15143C2.41475 7.13126 6.0968 10.7329 10.5 10.7329C14.9033 10.7329 18.5853 7.13126 19.506 6.15143C19.6355 6.0118 19.6355 5.80411 19.506 5.66448Z"
                  stroke="#606060"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
          case "close":
            return (
              <svg
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="46" height="46" rx="10" fill="#ddecff" />
                <path
                  d="M16 16L30 30"
                  stroke="#2c2c2c"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M16 30L30 16"
                  stroke="#2c2c2c"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            );
          case "error_16":
            return (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="8" cy="8" r="8" fill="#FF7070" />
                <rect
                  x="7.33325"
                  y="4"
                  width="1.33333"
                  height="5.33333"
                  rx="0.666667"
                  fill="white"
                />
                <rect
                  x="7.33325"
                  y="10.667"
                  width="1.33333"
                  height="1.33333"
                  rx="0.666667"
                  fill="white"
                />
              </svg>
            );
          case "redo":
            return (
              <svg
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.2196 1V4.04321H7.14587"
                  stroke="#606060"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.2197 4.04383C8.57021 1.79767 5.40194 1.28447 3.11541 2.89306C1.7964 3.82618 1.00965 5.32919 1.00009 6.93418C0.990525 8.53917 1.75931 10.0513 3.0671 10.9997"
                  stroke="#606060"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
          case "all":
            return (
              <svg
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="27" cy="27" r="27" fill="#F9424E" />
                <rect
                  x="18.5"
                  y="14.5"
                  width="18"
                  height="23"
                  rx="1.5"
                  fill="#F9424E"
                  stroke="white"
                />
                <path d="M22 21H33" stroke="white" strokeLinecap="round" />
                <path d="M22 26H33" stroke="white" strokeLinecap="round" />
                <path d="M22 31H33" stroke="white" strokeLinecap="round" />
              </svg>
            );
          case "all_active":
            return (
              <svg
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="27"
                  cy="27"
                  r="26"
                  fill="white"
                  stroke="#F9424E"
                  strokeWidth="2"
                />
                <rect
                  x="18.5"
                  y="14.5"
                  width="18"
                  height="23"
                  rx="1.5"
                  fill="white"
                  stroke="#F9424E"
                />
                <path d="M22 21H33" stroke="#F9424E" strokeLinecap="round" />
                <path d="M22 26H33" stroke="#F9424E" strokeLinecap="round" />
                <path d="M22 31H33" stroke="#F9424E" strokeLinecap="round" />
              </svg>
            );
          case "acp":
            return (
              <svg
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="27" cy="27" r="27" fill="#F9424E" />
                <path
                  d="M20 38.3594V18C20 16.8954 20.8954 16 22 16H31.9219C33.0265 16 33.9219 16.8954 33.9219 18V38.3594"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <rect
                  x="23"
                  y="20"
                  width="3"
                  height="3"
                  rx="0.5"
                  fill="white"
                />
                <rect
                  x="23"
                  y="25"
                  width="3"
                  height="3"
                  rx="0.5"
                  fill="white"
                />
                <rect
                  x="23"
                  y="30"
                  width="3"
                  height="3"
                  rx="0.5"
                  fill="white"
                />
                <rect
                  x="28"
                  y="20"
                  width="3"
                  height="3"
                  rx="0.5"
                  fill="white"
                />
                <rect
                  x="28"
                  y="25"
                  width="3"
                  height="3"
                  rx="0.5"
                  fill="white"
                />
                <rect
                  x="28"
                  y="30"
                  width="3"
                  height="3"
                  rx="0.5"
                  fill="white"
                />
              </svg>
            );
          case "acp_active":
            return (
              <svg
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="27"
                  cy="27"
                  r="26"
                  fill="white"
                  stroke="#F9424E"
                  strokeWidth="2"
                />
                <path
                  d="M20 38.3594V18C20 16.8954 20.8954 16 22 16H31.9219C33.0265 16 33.9219 16.8954 33.9219 18V38.3594"
                  stroke="#F9424E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <rect
                  x="23"
                  y="20"
                  width="3"
                  height="3"
                  rx="0.5"
                  fill="#F9424E"
                />
                <rect
                  x="23"
                  y="25"
                  width="3"
                  height="3"
                  rx="0.5"
                  fill="#F9424E"
                />
                <rect
                  x="23"
                  y="30"
                  width="3"
                  height="3"
                  rx="0.5"
                  fill="#F9424E"
                />
                <rect
                  x="28"
                  y="20"
                  width="3"
                  height="3"
                  rx="0.5"
                  fill="#F9424E"
                />
                <rect
                  x="28"
                  y="25"
                  width="3"
                  height="3"
                  rx="0.5"
                  fill="#F9424E"
                />
                <rect
                  x="28"
                  y="30"
                  width="3"
                  height="3"
                  rx="0.5"
                  fill="#F9424E"
                />
              </svg>
            );
          case "sd":
            return (
              <svg
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="27" cy="27" r="27" fill="#F9424E" />
                <path
                  d="M34 35V32.6667C34 31.429 33.6576 30.242 33.0481 29.3668C32.4386 28.4917 31.612 28 30.75 28H24.25C23.388 28 22.5614 28.4917 21.9519 29.3668C21.3424 30.242 21 31.429 21 32.6667V35"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M42 36V34.6667C42 33.9594 41.7103 33.2811 41.1945 32.781C40.6788 32.281 39.9793 32 39.25 32H33.75C33.0207 32 32.3212 32.281 31.8055 32.781C31.2897 33.2811 31 33.9594 31 34.6667V36"
                  fill="#F9424E"
                />
                <path
                  d="M42 36V34.6667C42 33.9594 41.7103 33.2811 41.1945 32.781C40.6788 32.281 39.9793 32 39.25 32H33.75C33.0207 32 32.3212 32.281 31.8055 32.781C31.2897 33.2811 31 33.9594 31 34.6667V36"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23 36V34.6667C23 33.9594 22.7103 33.2811 22.1945 32.781C21.6788 32.281 20.9793 32 20.25 32H14.75C14.0207 32 13.3212 32.281 12.8055 32.781C12.2897 33.2811 12 33.9594 12 34.6667V36"
                  fill="#F9424E"
                />
                <path
                  d="M23 36V34.6667C23 33.9594 22.7103 33.2811 22.1945 32.781C21.6788 32.281 20.9793 32 20.25 32H14.75C14.0207 32 13.3212 32.281 12.8055 32.781C12.2897 33.2811 12 33.9594 12 34.6667V36"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M27.5 24C29.433 24 31 22.433 31 20.5C31 18.567 29.433 17 27.5 17C25.567 17 24 18.567 24 20.5C24 22.433 25.567 24 27.5 24Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M37 28C38.6569 28 40 26.6569 40 25C40 23.3431 38.6569 22 37 22C35.3431 22 34 23.3431 34 25C34 26.6569 35.3431 28 37 28Z"
                  fill="#F9424E"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18 28C19.6569 28 21 26.6569 21 25C21 23.3431 19.6569 22 18 22C16.3431 22 15 23.3431 15 25C15 26.6569 16.3431 28 18 28Z"
                  fill="#F9424E"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
          case "sd_active":
            return (
              <svg
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="27"
                  cy="27"
                  r="26"
                  fill="white"
                  stroke="#F9424E"
                  strokeWidth="2"
                />
                <path
                  d="M34 35V32.6667C34 31.429 33.6576 30.242 33.0481 29.3668C32.4386 28.4917 31.612 28 30.75 28H24.25C23.388 28 22.5614 28.4917 21.9519 29.3668C21.3424 30.242 21 31.429 21 32.6667V35"
                  stroke="#F9424E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M42 36V34.6667C42 33.9594 41.7103 33.2811 41.1945 32.781C40.6788 32.281 39.9793 32 39.25 32H33.75C33.0207 32 32.3212 32.281 31.8055 32.781C31.2897 33.2811 31 33.9594 31 34.6667V36"
                  fill="white"
                />
                <path
                  d="M42 36V34.6667C42 33.9594 41.7103 33.2811 41.1945 32.781C40.6788 32.281 39.9793 32 39.25 32H33.75C33.0207 32 32.3212 32.281 31.8055 32.781C31.2897 33.2811 31 33.9594 31 34.6667V36"
                  stroke="#F9424E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23 36V34.6667C23 33.9594 22.7103 33.2811 22.1945 32.781C21.6788 32.281 20.9793 32 20.25 32H14.75C14.0207 32 13.3212 32.281 12.8055 32.781C12.2897 33.2811 12 33.9594 12 34.6667V36"
                  fill="white"
                />
                <path
                  d="M23 36V34.6667C23 33.9594 22.7103 33.2811 22.1945 32.781C21.6788 32.281 20.9793 32 20.25 32H14.75C14.0207 32 13.3212 32.281 12.8055 32.781C12.2897 33.2811 12 33.9594 12 34.6667V36"
                  stroke="#F9424E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M27.5 24C29.433 24 31 22.433 31 20.5C31 18.567 29.433 17 27.5 17C25.567 17 24 18.567 24 20.5C24 22.433 25.567 24 27.5 24Z"
                  stroke="#F9424E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M37 28C38.6569 28 40 26.6569 40 25C40 23.3431 38.6569 22 37 22C35.3431 22 34 23.3431 34 25C34 26.6569 35.3431 28 37 28Z"
                  fill="white"
                  stroke="#F9424E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18 28C19.6569 28 21 26.6569 21 25C21 23.3431 19.6569 22 18 22C16.3431 22 15 23.3431 15 25C15 26.6569 16.3431 28 18 28Z"
                  fill="white"
                  stroke="#F9424E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
          case "ap":
            return (
              <svg
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="27" cy="27" r="27" fill="#F9424E" />
                <rect
                  x="18.5"
                  y="16.6533"
                  width="15.2002"
                  height="21.8464"
                  rx="1.5"
                  fill="#F9424E"
                  stroke="white"
                />
                <line
                  x1="21.8232"
                  y1="21.4688"
                  x2="30.3772"
                  y2="21.4688"
                  stroke="white"
                  strokeLinecap="round"
                />
                <line
                  x1="21.8232"
                  y1="25.2075"
                  x2="30.3772"
                  y2="25.2075"
                  stroke="white"
                  strokeLinecap="round"
                />
                <line
                  x1="21.8232"
                  y1="28.9458"
                  x2="30.3772"
                  y2="28.9458"
                  stroke="white"
                  strokeLinecap="round"
                />
                <line
                  x1="21.8232"
                  y1="32.6846"
                  x2="30.3772"
                  y2="32.6846"
                  stroke="white"
                  strokeLinecap="round"
                />
                <circle
                  cx="34.4085"
                  cy="17.6078"
                  r="5.10776"
                  fill="#F9424E"
                  stroke="white"
                />
                <path
                  d="M34.5732 14.3086V17.7722H37.8719"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
          case "ap_active":
            return (
              <svg
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="27"
                  cy="27"
                  r="26"
                  fill="white"
                  stroke="#F9424E"
                  strokeWidth="2"
                />
                <rect
                  x="18.5"
                  y="16.6533"
                  width="15.2002"
                  height="21.8464"
                  rx="1.5"
                  fill="white"
                  stroke="#F9424E"
                />
                <line
                  x1="21.8232"
                  y1="21.4688"
                  x2="30.3772"
                  y2="21.4688"
                  stroke="#F9424E"
                  strokeLinecap="round"
                />
                <line
                  x1="21.8232"
                  y1="25.2075"
                  x2="30.3772"
                  y2="25.2075"
                  stroke="#F9424E"
                  strokeLinecap="round"
                />
                <line
                  x1="21.8232"
                  y1="28.9458"
                  x2="30.3772"
                  y2="28.9458"
                  stroke="#F9424E"
                  strokeLinecap="round"
                />
                <line
                  x1="21.8232"
                  y1="32.6846"
                  x2="30.3772"
                  y2="32.6846"
                  stroke="#F9424E"
                  strokeLinecap="round"
                />
                <circle
                  cx="34.4085"
                  cy="17.6078"
                  r="5.10776"
                  fill="white"
                  stroke="#F9424E"
                />
                <path
                  d="M34.5732 14.3086V17.7722H37.8719"
                  stroke="#F9424E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
          case "pp":
            return (
              <svg
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="27" cy="27" r="27" fill="#F9424E" />
                <path
                  d="M23 27L26 30L32 23"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle cx="27.5" cy="26.5" r="12" stroke="white" />
              </svg>
            );
          case "pp_active":
            return (
              <svg
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="27"
                  cy="27"
                  r="26"
                  fill="white"
                  stroke="#F9424E"
                  strokeWidth="2"
                />
                <path
                  d="M23 27L26 30L32 23"
                  stroke="#F9424E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle cx="27.5" cy="26.5" r="12" stroke="#F9424E" />
              </svg>
            );
          case "bda":
            return (
              <svg
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="27" cy="27" r="27" fill="#F9424E" />
                <circle cx="27" cy="27" r="27" stroke="#F9424E" />
                <rect
                  x="20.5"
                  y="23.5"
                  width="5"
                  height="14"
                  rx="2.5"
                  stroke="white"
                />
                <rect
                  x="28.5"
                  y="16.5"
                  width="5"
                  height="21"
                  rx="2.5"
                  stroke="white"
                />
              </svg>
            );
          case "bda_active":
            return (
              <svg
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="27"
                  cy="27"
                  r="26"
                  fill="white"
                  stroke="#F9424E"
                  strokeWidth="2"
                />
                <rect
                  x="20.5"
                  y="23.5"
                  width="5"
                  height="14"
                  rx="2.5"
                  stroke="#F9424E"
                />
                <rect
                  x="28.5"
                  y="16.5"
                  width="5"
                  height="21"
                  rx="2.5"
                  stroke="#F9424E"
                />
              </svg>
            );
          case "download":
            return (
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12.5"
                  cy="12.5"
                  r="12"
                  fill="white"
                  stroke="#2C2C2C"
                />
                <path
                  d="M12.5 6.66699L12.5 14.167"
                  stroke="#2C2C2C"
                  strokeLinecap="round"
                />
                <path
                  d="M7.5 13.333V16.6663H17.5V13.333"
                  stroke="#2C2C2C"
                  strokeLinecap="round"
                />
                <path
                  d="M10 11.667L12.5 14.167L15 11.667"
                  stroke="#2C2C2C"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};

export default Icon;
