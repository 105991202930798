import {
  pos_program_id_chk,
  pos_program_name_chk,
  course_name_chk,
  cluster_id_chk,
  cluster_name_chk,
} from "./field-chk-methods";

export const bdaSapsColDefsMap = {
  POS_Program_ID: {
    headerName: "POS_Program_ID",
    cellStyle: (params) => pos_program_id_chk(params),
  },
  POS_Program_Name: {
    headerName: "POS_Program_Name",
    cellStyle: (params) => pos_program_name_chk(params),
  },
  Course_1: {
    headerName: "Course_1",
    cellStyle: (params) => course_name_chk(params),
  },
  Course_2: {
    headerName: "Course_2",
    cellStyle: (params) => course_name_chk(params),
  },
  Course_3: {
    headerName: "Course_3",
    cellStyle: (params) => course_name_chk(params),
  },
  Course_4: {
    headerName: "Course_4",
    cellStyle: (params) => course_name_chk(params),
  },
  Cluster_ID: {
    headerName: "Cluster_ID",
    cellStyle: (params) => cluster_id_chk(params),
  },
  Cluster_Name: {
    headerName: "Cluster_Name",
    cellStyle: (params) => cluster_name_chk(params),
  },
};
