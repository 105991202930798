import {
  student_id_chk,
  semester_chk,
  high_school_gpa_chk,
  ftic_chk,
  lgpa_hours_attempted_chk,
  tgpa_hours_attempted_chk,
} from "./field-chk-methods";

export const apSgiColDefsMap = {
  Student_ID: {
    headerName: "Student_ID",
    cellStyle: (params) => student_id_chk(params),
  },
  SEMESTER: {
    headerName: "SEMESTER",
    cellStyle: (params) => semester_chk(params),
  },
  HIGH_SCHOOL_REPORTED_GPA: {
    headerName: "HIGH_SCHOOL_REPORTED_GPA",
    cellStyle: (params) => high_school_gpa_chk(params, 0, 5),
  },
  OVERALL_LGPA_GPA: {
    headerName: "OVERALL_LGPA_GPA",
    cellStyle: (params) => high_school_gpa_chk(params, 0, 4),
  },
  OVERALL_LGPA_HOURS_ATTEMPTED: {
    headerName: "OVERALL_LGPA_HOURS_ATTEMPTED",
    cellStyle: (params) => lgpa_hours_attempted_chk(params),
  },
  OVERALL_LGPA_HOURS_EARNED: {
    headerName: "OVERALL_LGPA_HOURS_EARNED",
    cellStyle: (params) => lgpa_hours_attempted_chk(params),
  },
  OVERALL_TGPA_GPA: {
    headerName: "OVERALL_TGPA_GPA",
    cellStyle: (params) => high_school_gpa_chk(params, 0, 4),
  },
  OVERALL_TGPA_HOURS_ATTEMPTED: {
    headerName: "OVERALL_TGPA_HOURS_ATTEMPTED",
    cellStyle: (params) => tgpa_hours_attempted_chk(params),
  },
  OVERALL_TGPA_HOURS_EARNED: {
    headerName: "OVERALL_TGPA_HOURS_EARNED",
    cellStyle: (params) => tgpa_hours_attempted_chk(params),
  },
  FTIC: {
    headerName: "FTIC",
    cellStyle: (params) => ftic_chk(params),
  },
};
