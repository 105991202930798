import { Modal } from "react-bootstrap";
import "../../Form.css";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./UploadFileModal.module.css";
import Icon from "../Icon/Icon";
import useUploadFileModal from "./useUploadFileModal";
import cx from "classnames";

const UploadFileModal = ({ show, handleClose, data }) => {
  const {
    hiddenFileInput,
    onInputClick,
    handleUpload,
    handleChange,
  } = useUploadFileModal(handleClose);
  return (
    <>
      {data && (
        <Modal
          show={show}
          backdrop="static"
          keyboard={false}
          dialogClassName={cx(styles.minWidth)}
          className="modal"
          backdropClassName="modal-backdrop"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className={cx(styles.titleUpl)}>
            <span>
              <label className={cx(styles.titleUplLbl)}>{data.name}</label>
              <label className={cx(styles.subTitleUplLbl)}>
                Format in .csv
              </label>
            </span>
            <span onClick={handleClose} className={cx(styles.closeBtn)}>
              <Icon name="close"></Icon>
            </span>
          </div>
          <div className={cx(styles.content)}>
            {data.files.map((file, index) => (
              <div className={cx(styles.card)} key={file.id}>
                <span className={cx(styles.cardUplLbl)}>{file.name}</span>
                <input
                  type="file"
                  accept=".csv"
                  ref={(element) => (hiddenFileInput.current[index] = element)}
                  onChange={(e) => handleChange(e, file)}
                  onClick={onInputClick}
                  style={{ display: "none" }}
                />
                <button
                  className={cx(styles.submitBtn)}
                  onClick={() => handleUpload(index)}
                >
                  Submit
                </button>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default UploadFileModal;
