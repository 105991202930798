import { createContext, useReducer } from "react";
import {
  activeMenuReducer,
  fileListReducer,
  fileHistoryListReducer,
  verifyFileListReducer,
  reportListReducer,
} from "./reducer";
import {
  activeMenuInitialState,
  fileListInitialState,
  fileHistoryListInitialState,
  verifyFileListInitialState,
  reportListInitialState,
} from "./initialState";
export const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
  const [activeMenuState, activeMenuDispatch] = useReducer(
    activeMenuReducer,
    activeMenuInitialState
  );
  const [fileList, fileListDispatch] = useReducer(
    fileListReducer,
    fileListInitialState
  );

  const [fileHistoryList, fileHistoryListDispatch] = useReducer(
    fileHistoryListReducer,
    fileHistoryListInitialState
  );

  const [verifyFileList, verifyFileListDispatch] = useReducer(
    verifyFileListReducer,
    verifyFileListInitialState
  );
  const [reportList, reportListDispatch] = useReducer(
    reportListReducer,
    reportListInitialState
  );

  // const [loginState, loginDispatch] = useReducer(
  //   loginReducer,
  //   loginInitialState
  // );
  return (
    <GlobalContext.Provider
      value={{
        activeMenuState,
        activeMenuDispatch,
        fileList,
        fileListDispatch,
        fileHistoryList,
        fileHistoryListDispatch,
        verifyFileList,
        verifyFileListDispatch,
        reportList,
        reportListDispatch,
        // regState,
        // regDispatch,
        // loginState,
        // loginDispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
