import { useState, useEffect, useRef, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import {
  uploadApi,
  // getFileStatusApi,
  downloadFileApi,
} from "../../context/actions";
import parser from "../../utils/parser";
import { GRID_ERROR, GRID_INCORRECT } from "../../constants/actionTypes";

const useDataSubmit = () => {
  const [showErrorScreen, setShowErrorScreen] = useState(false);
  const [file, setFile] = useState({});

  const handleShowError = (show, file) => {
    setShowErrorScreen(show);
    setFile(file);
  };

  const {
    activeMenuState: { activeMenu },
  } = useContext(GlobalContext);
  const { fileList, fileListDispatch } = useContext(GlobalContext);
  const [fileListArr, setFileListArr] = useState(fileList);

  const { fileHistoryList, fileHistoryListDispatch } =
    useContext(GlobalContext);
  const [fileHistoryListArr, setFileHistoryListArr] = useState(fileHistoryList);

  const [toViewHistory, setToViewHistory] = useState(false);

  useEffect(() => {
    if (activeMenu === "all") {
      setFileListArr(fileList);
      return;
    }
    setFileListArr(fileList.filter((file) => file.id === activeMenu));
  }, [activeMenu, fileList]);

  useEffect(() => {
    if (activeMenu === "all") {
      setFileHistoryListArr(fileHistoryList);
      return;
    }
    setFileHistoryListArr(
      fileHistoryList.filter((file) => file.id === activeMenu)
    );
  }, [activeMenu, fileHistoryList]);

  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const handleDownloadClose = () => setShowDownloadModal(false);
  const handleDownloadShow = (e) => {
    e.preventDefault();
    setShowDownloadModal(true);
  };

  const [showUploadModal, setShowUploadModal] = useState(false);
  const handleUploadClose = () => setShowUploadModal(false);
  const [filesData, setFilesData] = useState();

  const handleUploadShow = (filesObj) => {
    setShowUploadModal(true);
    setFilesData(filesObj);
  };

  const [showUploadHistoryModal, setShowUploadHistoryModal] = useState(false);
  const handleUploadHistoryClose = () => setShowUploadHistoryModal(false);
  const [historyFilesData, setHistoryFilesData] = useState();

  const handleUploadHistoryShow = (filesObj) => {
    setShowUploadHistoryModal(true);
    setHistoryFilesData(filesObj);
  };

  const hiddenFileInput = useRef({});
  const onInputClick = (e) => {
    e.target.value = "";
  };
  const handleUpload = (index) => {
    hiddenFileInput.current[index].click();
  };
  const handleChange = async (e, file) => {
    const fileUploaded = e.target.files[0];
    //1. pass file object to parser
    const { success, gridError } = await parser(fileUploaded, file);
    if (success) {
      //2. if no errors call upload api
      uploadApi(fileUploaded, file.id)(fileListDispatch);
    } else {
      if (gridError.columnDefs) {
        //3. update fileList with columnDefs and rowData
        fileListDispatch({
          type: GRID_ERROR,
          fileId: file.id,
          gridError,
        });
      } else {
        fileListDispatch({
          type: GRID_INCORRECT,
          fileId: file.id,
          gridError,
        });
      }
    }
  };

  const handleDownload = (fileId) => {
    downloadFileApi(`tmp-${fileId}`);
  };

  const handleViewHistory = () => {
    if (toViewHistory) {
      setToViewHistory(false);
    } else {
      setToViewHistory(true);
    }
  };

  // useEffect(() => {
  //   getFileStatusApi()(fileListDispatch);
  // }, []);

  return {
    showDownloadModal,
    handleDownloadShow,
    handleDownloadClose,
    showUploadModal,
    handleUploadShow,
    handleUploadClose,
    showUploadHistoryModal,
    handleUploadHistoryShow,
    handleUploadHistoryClose,
    historyFilesData,
    fileHistoryListArr,
    toViewHistory,
    handleViewHistory,
    fileListArr,
    filesData,
    hiddenFileInput,
    onInputClick,
    handleUpload,
    handleChange,
    showErrorScreen,
    handleShowError,
    file,
    handleDownload,
  };
};

export default useDataSubmit;
