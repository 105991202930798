import {
  student_id_chk,
  semester_chk,
  program_id_chk,
  course_name_chk,
  course_code_chk,
  course_credit_chk,
  course_grade_chk,
} from "./field-chk-methods";

export const apSciColDefsMap = {
  Student_ID: {
    headerName: "Student_ID",
    cellStyle: (params) => student_id_chk(params),
  },
  SEMESTER: {
    headerName: "SEMESTER",
    cellStyle: (params) => semester_chk(params),
  },
  Course_ID: {
    headerName: "Course_ID",
    cellStyle: (params) => program_id_chk(params),
  },
  Course_Code: {
    headerName: "Course_Code",
    cellStyle: (params) => course_code_chk(params),
  },
  Course_Name: {
    headerName: "Course_Name",
    cellStyle: (params) => course_name_chk(params),
  },
  Course_Credit_Total: {
    headerName: "Course_Credit_Total",
    cellStyle: (params) => course_credit_chk(params, 0, 5),
  },
  Course_Credit_Earned: {
    headerName: "Course_Credit_Earned",
    cellStyle: (params) => course_credit_chk(params, 0, 5),
  },
  Course_Grade: {
    headerName: "Course_Grade",
    cellStyle: (params) => course_grade_chk(params),
  },
};
