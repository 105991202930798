import { Modal } from "react-bootstrap";
import "../../Form.css";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./DownloadFileModal.module.css";
import Icon from "../Icon/Icon";
import useDownloadFileModal from "./useDownloadFileModal";
import cx from "classnames";

const DownloadFileModal = ({ show, handleClose }) => {
  const { fileList, handleDownload } = useDownloadFileModal();
  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        dialogClassName={cx(styles.minWidth)}
        className="modal"
        backdropClassName="modal-backdrop"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className={cx(styles.title)}>
          <label className={cx(styles.titleLbl)}>Data Templates</label>
          <span onClick={handleClose}>
            <Icon name="close"></Icon>
          </span>
        </div>

        <div className={cx(styles.content)}>
          <div className={cx(styles.cardDownload)}>
            <div className={cx(styles.cardDwnHeader)}>
              <span className={cx(styles.cardLbl)}>Download All Files</span>
              <button
                className={cx(styles.dwnldBtn)}
                onClick={() => handleDownload("all")}
              >
                Download
              </button>
            </div>
          </div>

          {fileList.map((file) => (
            <div className={cx(styles.cardDownload)} key={file.id}>
              <div className={cx(styles.cardDwnHeader)}>
                <span className={cx(styles.cardLbl)}>{file.name}</span>
                <button
                  className={cx(styles.dwnldBtn)}
                  onClick={() => handleDownload(file.id)}
                >
                  Download
                </button>
              </div>
              {file.files.map((f) => (
                <div key={f.id}>
                  <span className={cx(styles.cardSubLbl)}>{f.name}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default DownloadFileModal;
