import { useState, useContext, useEffect, useReducer } from "react";
// import { GlobalContext } from "../../context/provider";
import { registerApi } from "../../context/actions";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { RESET } from "../../constants/actionTypes";
import validate from "./validateRegister";
import { registerReducer } from "../../context/reducer";
import { regInitialState } from "../../context/initialState";

const useRegisterForm = () => {
  const { addToast } = useToasts();
  const [form, setValues] = useState({
    username: "",
    email: "",
    mobile: "",
    password: "",
    confPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setisSubmitted] = useState(false);
  const history = useHistory();

  // const {
  //   regDispatch,
  //   regState: { loading, payload },
  // } = useContext(GlobalContext);

  const [{ payload, loading }, dispatch] = useReducer(
    registerReducer,
    regInitialState
  );
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...form,
      [name]: value,
    });

    if (name === "mobile") {
      if (value.length === 10 && value.match(/^\d{10}$/)) {
        setValues({
          ...form,
          ["mobile"]:
            value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6),
        });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(form));
    setisSubmitted(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitted) {
      registerApi(form)(dispatch);
    }
  }, [errors]);

  useEffect(() => {
    if (payload) {
      if (payload.success) {
        history.push("/login");
      }
      addToast(payload.msg, {
        appearance: payload.success ? "success" : "error",
        autoDismiss: true,
      });
    }
    return () => {
      dispatch({
        type: RESET,
      });
    };
  }, [payload]);

  return {
    handleChange,
    handleSubmit,
    form,
    errors,
    loading,
  };
};

export default useRegisterForm;
