import { useContext } from "react";
import { GlobalContext } from "../../context/provider";
import { downloadFileApi } from "../../context/actions";

const useDownloadFileModal = () => {
  const handleDownload = (fileId) => {
    downloadFileApi(`tmp-${fileId}`);
  };
  const { fileList } = useContext(GlobalContext);

  return { fileList, handleDownload };
};

export default useDownloadFileModal;
