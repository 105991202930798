import { useState, useEffect, useReducer } from "react";
import { forgotPasswordApi } from "../../context/actions";
import { useToasts } from "react-toast-notifications";
import { RESET } from "../../constants/actionTypes";
import validate from "./validateForgotPassword";
import { forgotPasswordReducer } from "../../context/reducer";
import { forgotPasswordInitialState } from "../../context/initialState";

const useForgotPassword = () => {
  const { addToast } = useToasts();
  const [form, setValues] = useState({
    username: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(form));
    setIsSubmitted(true);
  };

  const [{ payload, loading }, dispatch] = useReducer(
    forgotPasswordReducer,
    forgotPasswordInitialState
  );

  useEffect(() => {
    // if (Object.keys(errors).length === 0 && isSubmitted && isVerified) {
    if (Object.keys(errors).length === 0 && isSubmitted) {
      forgotPasswordApi(form)(dispatch);
    }
  }, [errors]);

  useEffect(() => {
    if (payload) {
      addToast(payload.msg, {
        appearance: payload.success ? "success" : "error",
        autoDismiss: true,
      });
    }
    return () => {
      dispatch({
        type: RESET,
      });
    };
  }, [payload]);

  return {
    handleChange,
    handleSubmit,
    form,
    errors,
    loading,
  };
};

export default useForgotPassword;
