import { useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import maskEmailer from "../../utils/maskEmailer";
import { sendEmailReducer } from "../../context/reducer";
import { sendEmailInitialState } from "../../context/initialState";
import { sendEmailApi } from "../../context/actions";
import { RESET } from "../../constants/actionTypes";

const useValidateEmail = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const email = localStorage.email;
  const maskEmail = maskEmailer(email);

  const [
    { payload: emailPayload, loading: emailLoader },
    emailDispatch,
  ] = useReducer(sendEmailReducer, sendEmailInitialState);

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push("/validate");
  };

  const handleResend = (e) => {
    e.preventDefault();
    sendEmailApi({ email: email })(emailDispatch);
  };

  useEffect(() => {
    if (emailPayload) {
      if (emailPayload.success) {
        // no success case, just show toast notification
      }
      addToast(emailPayload.msg, {
        appearance: emailPayload.success ? "success" : "error",
        autoDismiss: true,
      });
    }
    return () => {
      emailDispatch({
        type: RESET,
      });
    };
  }, [emailPayload]);

  return {
    maskEmail,
    handleResend,
    handleSubmit,
  };
};

export default useValidateEmail;
