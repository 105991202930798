import React from "react";
import cx from "classnames";
import styles from "./Register.module.css";
import "../../Form.css";
import { Carousel, Footer, Title, Icon, ErrorTooltip } from "../../components";
import { Link } from "react-router-dom";
import useRegisterForm from "./useRegisterForm";

export const Register = () => {
  const { handleChange, handleSubmit, form, errors, loading } =
    useRegisterForm();

  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.flexChild)}>
        <div className={cx(styles.content)}>
          <div className={cx(styles.outerContent)}>
            <Title title="Join Us" subTitle="Now" />
            <form onSubmit={handleSubmit}>
              <div className={cx(styles.form)}>
                <div className={cx(styles.inputForm)}>
                  {errors.username && (
                    <div className="error-icon">
                      <ErrorTooltip
                        title={errors.username}
                        placement="right"
                        interactive
                      >
                        <div>
                          <Icon name="error" />
                        </div>
                      </ErrorTooltip>
                    </div>
                  )}
                  <label htmlFor="username" className="form-label">
                    Username
                  </label>
                  <input
                    id="username"
                    type="text"
                    name="username"
                    className={cx("form-input", {
                      "form-input-error": errors.username,
                    })}
                    placeholder="Create your username"
                    value={form.username}
                    onChange={handleChange}
                  />
                </div>
                <div className={cx(styles.inputForm)}>
                  {errors.email && (
                    <div className="error-icon">
                      <ErrorTooltip
                        title={errors.email}
                        placement="right"
                        interactive
                      >
                        <div>
                          <Icon name="error" />
                        </div>
                      </ErrorTooltip>
                    </div>
                  )}
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    className={cx("form-input", {
                      "form-input-error": errors.email,
                    })}
                    placeholder="address@example.com"
                    value={form.email}
                    onChange={handleChange}
                  />
                </div>
                <div className={cx(styles.inputForm)}>
                  {errors.mobile && (
                    <div className="error-icon">
                      <ErrorTooltip
                        title={errors.mobile}
                        placement="right"
                        interactive
                      >
                        <div>
                          <Icon name="error" />
                        </div>
                      </ErrorTooltip>
                    </div>
                  )}
                  <label htmlFor="mobile" className="form-label">
                    Mobile number
                  </label>
                  <input
                    id="mobile"
                    type="tel"
                    name="mobile"
                    className={cx("form-input", {
                      "form-input-error": errors.mobile,
                    })}
                    placeholder="Number format is 000-000-0000"
                    value={form.mobile}
                    onChange={handleChange}
                  />
                </div>
                <div className={cx(styles.inputForm)}>
                  {errors.password && (
                    <div className="error-icon">
                      <ErrorTooltip
                        title={errors.password}
                        placement="right"
                        interactive
                      >
                        <div>
                          <Icon name="error" />
                        </div>
                      </ErrorTooltip>
                    </div>
                  )}
                  <label htmlFor="password" className="form-label">
                    Set password
                  </label>
                  <input
                    id="password"
                    type="password"
                    name="password"
                    className={cx("form-input", {
                      "form-input-error": errors.password,
                    })}
                    placeholder="Create a strong password (Minimum 8 letters)"
                    value={form.password}
                    onChange={handleChange}
                  />
                </div>
                <div className={cx(styles.inputForm)}>
                  {errors.confPassword && (
                    <div className="error-icon">
                      <ErrorTooltip
                        title={errors.confPassword}
                        placement="right"
                        interactive
                      >
                        <div>
                          <Icon name="error" />
                        </div>
                      </ErrorTooltip>
                    </div>
                  )}
                  <label htmlFor="confPassword" className="form-label">
                    Re-enter password
                  </label>
                  <input
                    id="confPassword"
                    type="password"
                    name="confPassword"
                    className={cx("form-input", {
                      "form-input-error": errors.confPassword,
                    })}
                    placeholder="Re-enter password"
                    value={form.confPassword}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={cx(styles.action)}>
                <button
                  disabled={loading}
                  className="form-input-btn"
                  type="submit"
                >
                  Submit
                </button>
                <span>
                  <label className="form-label form-padding-left-20px">
                    Already have an account?{" "}
                  </label>
                  <Link className="form-label-link" to="/login">
                    Log in
                  </Link>
                </span>
              </div>
            </form>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
      <div className={cx(styles.flexChild)}>
        <Carousel />
      </div>
    </div>
  );
};

export default Register;
