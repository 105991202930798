import cx from 'classnames'
import styles from './Title.module.css'
import PropTypes from 'prop-types'

const Title = ({title, subTitle}) => {
    return (
        <span>
            <p className={cx(styles.title)}>{title}</p>
            <p className={cx(styles.subtitle)}>{subTitle}</p>
        </span>
    )
}

Title.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string
}

export default Title
