import cx from "classnames";
import styles from "./Home.module.css";
import Header from "../../components/Header/Header";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import DataSubmit from "../../components/DataSubmit/DataSubmit";
import DataGovernance from "../../components/DataGovernance/DataGovernance";
import DataReporting from "../../components/DataReporting/DataReporting";

export const Home = () => {
  return (
    <div>
      <Header />
      <div className={cx(styles.container)}>
        <nav className={cx(styles.nav)}>
          <Sidebar />
        </nav>
        <div className={cx(styles.content)}>
          <Tabs selectedTabClassName={cx(styles.selectedTab)}>
            <TabList className={cx(styles.tabList)}>
              <Tab className={cx(styles.tab)}>Data Submission</Tab>
              <Tab className={cx(styles.tab)}>Data Governance</Tab>
              <Tab className={cx(styles.tab)}>Data Reporting</Tab>
            </TabList>
            <TabPanel>
              <DataSubmit />
            </TabPanel>
            <TabPanel>
              <DataGovernance />
            </TabPanel>
            <TabPanel>
              <DataReporting />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Home;
