import { useEffect, useReducer } from "react";
import { verifyEmailApi } from "../../context/actions";
import { useLocation, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { RESET } from "../../constants/actionTypes";
import { verifyEmailReducer } from "../../context/reducer";
import { verifyEmailInitialState } from "../../context/initialState";
import axiosInstance from "../../helpers/axios";

export const VerifyEmail = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const validateToken = query.get("validate-token");
  const [{ payload, loading }, verifyEmailDispatch] = useReducer(
    verifyEmailReducer,
    verifyEmailInitialState
  );

  useEffect(() => {
    verifyEmailApi({ validate_token: validateToken })(verifyEmailDispatch);
  }, []);

  useEffect(() => {
    if (payload) {
      if (payload.success) {
        localStorage.setItem("token", payload.data.token);
        axiosInstance.defaults.headers["Authorization"] =
          "Bearer " + localStorage.getItem("token");

        history.push("/");
      } else {
        localStorage.clear();
        history.push("/login");
      }
      addToast(payload.msg, {
        appearance: payload.success ? "success" : "error",
        autoDismiss: true,
      });
    }
    return () => {
      verifyEmailDispatch({
        type: RESET,
      });
    };
  }, [payload]);

  return (
    <div>
      <h1 style={{ textAlign: "center", color: "#6DD5D5" }}>Loading...</h1>
    </div>
  );
};

export default VerifyEmail;
