import { Home } from "../pages/Home/Home";
import { Login } from "../pages/Login/Login";
import { Register } from "../pages/Register/Register";
import { ForgotPassword } from "../pages/ForgotPassword/ForgotPassword";
import { ResetPassword } from "../pages/ResetPassword/ResetPassword";
import { UserProfile } from "../pages/UserProfile/UserProfile";
import { Validate } from "../pages/Validate/Validate";
import { ValidateOtp } from "../pages/ValidateOtp/ValidateOtp";
import { ValidateEmail } from "../pages/ValidateEmail/ValidateEmail";
import { VerifyEmail } from "../pages/VerifyEmail/VerifyEmail";

const routes = [
  {
    path: "/login",
    component: Login,
    title: "Login",
    needsAuth: false,
  },
  {
    path: "/register",
    component: Register,
    title: "Register",
    needsAuth: false,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    title: "Forgot Password",
    needsAuth: false,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    title: "Reset Password",
    needsAuth: false,
  },
  {
    path: "/user-profile",
    component: UserProfile,
    title: "User Profile",
    needsAuth: true,
  },
  {
    path: "/validate",
    component: Validate,
    title: "Validate",
    needsAuth: false,
  },
  {
    path: "/validate-email",
    component: ValidateEmail,
    title: "Validate email",
    needsAuth: false,
  },
  {
    path: "/validate-otp",
    component: ValidateOtp,
    title: "Validate otp",
    needsAuth: false,
  },
  {
    path: "/verify-email",
    component: VerifyEmail,
    title: "Verify Email",
    needsAuth: false,
  },
  {
    path: "/",
    component: Home,
    title: "Homepage",
    needsAuth: true,
  },
  {
    path: "/*",
    component: Register,
    title: "Register",
    needsAuth: false,
  },
];

export default routes;
