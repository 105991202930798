import axiosInstance from "../helpers/axios";
import "../constants/actionTypes";
import {
  REG_ERROR,
  REG_LOADING,
  REG_SUCCESS,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SEND_EMAIL_LOADING,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_ERROR,
  SEND_OTP_LOADING,
  SEND_OTP_SUCCESS,
  SEND_OTP_ERROR,
  VERIFY_EMAIL_LOADING,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR,
  VERIFY_OTP_LOADING,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
  UPLOAD_LOADING,
  UPLOAD_SUCCESS,
  UPLOAD_ERROR,
  FILE_STATUS_LOADING,
  SET_FILE_STATUS,
  FILE_STATUS_ERROR,
  UPLOAD_HISTORY_LOADING,
  UPLOAD_HISTORY_SUCCESS,
  UPLOAD_HISTORY_ERROR,
  UPLOAD_HISTORY_GRID_ERROR,
  VERIFY_DB_LOADING,
  VERIFY_DB_SUCCESS,
  VERIFY_DB_ERROR,
  VERIFY_DB_NO_CHECK,
  VERIFY_DB_GRID_ERROR,
  USER_PROFILE_LOADING,
  USER_PROFILE_SET,
  USER_PROFILE_UPDATE,
  USER_PROFILE_ERROR,
} from "../constants/actionTypes";
import { columnDefsMap } from "../helpers/db-verify/common";

export const registerApi =
  ({
    username,
    email,
    mobile: mobile_no,
    password,
    confPassword: conf_password,
  }) =>
  (dispatch) => {
    dispatch({
      type: REG_LOADING,
    });
    axiosInstance
      .post("/user/register/", {
        username,
        email,
        mobile_no,
        password,
        conf_password,
      })
      .then((res) => {
        dispatch({
          type: REG_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: REG_ERROR,
          payload: err.response ? err.response.data : "COULD NOT CONNECT",
        });
      });
  };

export const loginApi =
  ({ username, password, captchaToken: captcha_token }) =>
  (dispatch) => {
    dispatch({
      type: LOGIN_LOADING,
    });
    axiosInstance
      .post("/user/login/", { username, password, captcha_token })
      .then((res) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_ERROR,
          payload: err.response ? err.response.data : "COULD NOT CONNECT",
        });
      });
  };

export const forgotPasswordApi =
  ({ username }) =>
  (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_LOADING,
    });
    axiosInstance
      .post("/user/forgot-password/", { username })
      .then((res) => {
        dispatch({
          type: FORGOT_PASSWORD_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FORGOT_PASSWORD_ERROR,
          payload: err.response ? err.response.data : "COULD NOT CONNECT",
        });
      });
  };

export const resetPasswordApi =
  ({ password, token }) =>
  (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_LOADING,
    });
    axiosInstance
      .post("/user/reset-password/", { password, token })
      .then((res) => {
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: RESET_PASSWORD_ERROR,
          payload: err.response ? err.response.data : "COULD NOT CONNECT",
        });
      });
  };

export const sendOtpApi =
  ({ mobile_no }) =>
  (dispatch) => {
    dispatch({
      type: SEND_OTP_LOADING,
    });
    axiosInstance
      .post("/user/send-otp/", { mobile_no })
      .then((res) => {
        dispatch({
          type: SEND_OTP_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SEND_OTP_ERROR,
          payload: err.response ? err.response.data : "COULD NOT CONNECT",
        });
      });
  };

export const sendEmailApi =
  ({ email }) =>
  (dispatch) => {
    dispatch({
      type: SEND_EMAIL_LOADING,
    });
    axiosInstance
      .post("/user/send-email/", { email })
      .then((res) => {
        dispatch({
          type: SEND_EMAIL_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SEND_EMAIL_ERROR,
          payload: err.response ? err.response.data : "COULD NOT CONNECT",
        });
      });
  };

export const verifyOtpApi =
  ({ mobile_no, otp }) =>
  (dispatch) => {
    dispatch({
      type: VERIFY_OTP_LOADING,
    });
    axiosInstance
      .get("/user/verify-otp/", { params: { mobile_no: mobile_no, otp: otp } })
      .then((res) => {
        dispatch({
          type: VERIFY_OTP_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: VERIFY_OTP_ERROR,
          payload: err.response ? err.response.data : "COULD NOT CONNECT",
        });
      });
  };

export const verifyEmailApi =
  ({ validate_token }) =>
  (dispatch) => {
    dispatch({
      type: VERIFY_EMAIL_LOADING,
    });
    axiosInstance
      .get("/user/verify-email/", {
        params: { validate_token: validate_token },
      })
      .then((res) => {
        dispatch({
          type: VERIFY_EMAIL_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: VERIFY_EMAIL_ERROR,
          payload: err.response ? err.response.data : "COULD NOT CONNECT",
        });
      });
  };

export const downloadFileApi = (fileType) => {
  setTimeout(() => {
    const response = {
      file: `${process.env.REACT_APP_BACKEND_URL}data/download/?file_type=${fileType}`,
    };
    window.open(response.file);
  }, 100);
};

export const downloadReportApi = (
  reportType,
  fromDate,
  toDate,
  reportFormat
) => {
  setTimeout(() => {
    const response = {
      file: `${process.env.REACT_APP_BACKEND_URL}data/report/generate/?report_type=${reportType}&from_date=${fromDate}&to_date=${toDate}&report_format=${reportFormat}`,
    };
    window.open(response.file);
  }, 100);
};

export const uploadApi = (file, type) => (dispatch) => {
  dispatch({
    type: UPLOAD_LOADING,
    fileId: type,
  });
  const formData = new FormData();
  formData.append("file", file);
  formData.append("type", type);
  formData.append("desc", "app uploaded");
  axiosInstance
    .put("/data/upload/", formData)
    .then((res) => {
      dispatch({
        type: UPLOAD_SUCCESS,
        fileId: type,
      });
    })
    .catch((err) => {
      dispatch({
        type: UPLOAD_ERROR,
        payload: err.response ? err.response.data : "COULD NOT CONNECT",
        fileId: type,
      });
    });
};

export const verifyApi = (fileType) => (dispatch) => {
  dispatch({
    type: VERIFY_DB_LOADING,
    fileId: fileType,
  });

  axiosInstance
    .post("/data/verify/", { file_type: fileType })
    .then((res) => {
      if (res.data.success) {
        if (res.data.noCheck) {
          dispatch({
            type: VERIFY_DB_NO_CHECK,
            fileId: fileType,
          });
        } else {
          dispatch({
            type: VERIFY_DB_SUCCESS,
            fileId: fileType,
          });
        }
      } else {
        let columnDefs = [];
        for (let column of res.data.gridError.columnList) {
          if (columnDefsMap[fileType][column]) {
            let def = {
              headerName: columnDefsMap[fileType][column]["headerName"],
              field: column,
              filter: "agTextColumnFilter",
              filterParams: {
                buttons: ["reset", "apply"],
                closeOnApply: true,
              },
              cellStyle: (params) =>
                columnDefsMap[fileType][column]["cellStyle"](params),
            };

            columnDefs.push(def);
          }
        }

        let gridError = {
          columnDefs: columnDefs,
          rowData: res.data.gridError.rowData,
        };

        dispatch({
          type: VERIFY_DB_GRID_ERROR,
          fileId: fileType,
          gridError: gridError,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: VERIFY_DB_ERROR,
        payload: err.response ? err.response.data : "COULD NOT CONNECT",
      });
    });
};

export const getFileStatusApi = () => (dispatch) => {
  dispatch({
    type: FILE_STATUS_LOADING,
  });
  axiosInstance
    .get("/data/status/")
    .then((res) => {
      dispatch({
        type: SET_FILE_STATUS,
        fileStatus: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FILE_STATUS_ERROR,
        payload: err.response ? err.response.data : "COULD NOT CONNECT",
      });
    });
};

export const uploadHistoryApi = (fileType) => (dispatch) => {
  dispatch({
    type: UPLOAD_HISTORY_LOADING,
    fileId: fileType,
  });

  axiosInstance
    .get("/data/upload-history/", { params: { file_type: fileType } })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: UPLOAD_HISTORY_SUCCESS,
          fileId: fileType,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: UPLOAD_HISTORY_ERROR,
        payload: err.response ? err.response.data : "COULD NOT CONNECT",
      });
    });
};

export const getUserProfile = () => (dispatch) => {
  dispatch({
    type: USER_PROFILE_LOADING,
  });
  axiosInstance.get("/user/profile/").then((res) => {
    dispatch({
      type: USER_PROFILE_SET,
      payload: res.data.data,
    });
  });
};

export const setUserProfile = (form) => (dispatch) => {
  dispatch({
    type: USER_PROFILE_LOADING,
  });
  const requestData = {};
  // requestData["username"] = form["username"]["value"];
  requestData["email"] = form["email"]["value"];
  requestData["mobile_no"] = form["mobileNumber"]["value"];
  requestData["password"] = form["password"]["value"];
  // requestData["username_updated"] = form["username"]["updated"];
  requestData["email_updated"] = form["email"]["updated"];
  requestData["mobile_no_updated"] = form["mobileNumber"]["updated"];
  requestData["password_updated"] = form["password"]["updated"];

  axiosInstance
    .post("/user/profile/", requestData)
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: USER_PROFILE_UPDATE,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: USER_PROFILE_ERROR,
        payload: err.response.data,
      });
    });
};
