import cx from "classnames";
import styles from "./ValidateEmail.module.css";
import "../../Form.css";
import { Carousel, Footer, Title, Icon } from "../../components";
import { Link } from "react-router-dom";
import useValidateEmail from "./useValidateEmail";

export const ValidateEmail = () => {
  const { maskEmail, handleResend, handleSubmit } = useValidateEmail();

  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.flexChild)}>
        <div className={cx(styles.content)}>
          <div className={cx(styles.outerContent)}>
            <div className={cx(styles.backArrow)}>
              <Link to='/validate'>
                <Icon name='back' />
              </Link>
            </div>
            <Title title='Keep Your Account' subTitle='Secure' />
            <form onSubmit={handleSubmit}>
              <div className={cx(styles.form)}>
                <label className={cx(styles.lblNotify)}>
                  We have sent a verification code <br /> to
                  <b className='font-weight-500'> {maskEmail} </b>
                </label>
                <div className={cx(styles.marginTop)}>
                  <label className='form-label-gray'>
                    Didn't receive mail?{" "}
                  </label>
                  <a className='form-label-link' onClick={handleResend}>
                    Resend
                  </a>
                </div>
              </div>
              <div className={cx(styles.action)}>
                <button className='form-input-btn' type='submit'>
                  Go back
                </button>
              </div>
            </form>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
      <div className={cx(styles.flexChild)}>
        <Carousel />
      </div>
    </div>
  );
};

export default ValidateEmail;
