import { student_id_chk, program_name_chk } from "./field-chk-methods";

export const acpUdcCcPiSColDefsMap = {
  Student_ID: {
    headerName: "Student_ID",
    cellStyle: (params) => student_id_chk(params),
  },
  Program_Name: {
    headerName: "Program_Name",
    cellStyle: (params) => program_name_chk(params),
  },
};
