import { useRef, useEffect, useContext } from "react";
import { GRID_ERROR, GRID_INCORRECT } from "../../constants/actionTypes";
import { uploadApi } from "../../context/actions";
import { GlobalContext } from "../../context/provider";
import parser from "../../utils/parser";

const useUploadFileModal = (handleClose) => {
  const hiddenFileInput = useRef({});
  const onInputClick = (e) => {
    e.target.value = "";
  };
  const handleUpload = (index) => {
    hiddenFileInput.current[index].click();
  };
  const { fileList, fileListDispatch } = useContext(GlobalContext);

  useEffect(() => {}, [fileList]);
  const handleChange = async (e, file) => {
    const fileUploaded = e.target.files[0];
    //1. pass file object to parser
    const { success, gridError } = await parser(fileUploaded, file);
    handleClose();
    // reset previous errors
    if (success) {
      //2. if no errors call upload api
      uploadApi(fileUploaded, file.id)(fileListDispatch);
    } else {
      if (gridError.columnDefs) {
        //3. update fileList with columnDefs and rowData
        fileListDispatch({
          type: GRID_ERROR,
          fileId: file.id,
          gridError,
        });
      } else {
        fileListDispatch({
          type: GRID_INCORRECT,
          fileId: file.id,
          gridError,
        });
      }
    }
  };
  return { hiddenFileInput, onInputClick, handleUpload, handleChange };
};

export default useUploadFileModal;
