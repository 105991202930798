import React from "react";
import cx from "classnames";
import styles from "./DataSubmit.module.css";
import useDataSubmit from "./useDataSubmit";
import DownloadFileModal from "../Modal/DownloadFileModal";
import Icon from "../Icon/Icon";
import UploadHistoryModal from "../Modal/UploadHistoryModal";
import UploadFileModal from "../Modal/UploadFileModal";
import Loader from "../Loader/Loader";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const DataSubmit = () => {
  const hfCol = ["File", "Uploaded By", "Upload Time"];

  const {
    showDownloadModal,
    handleDownloadShow,
    handleDownloadClose,
    showUploadModal,
    handleUploadShow,
    handleUploadClose,
    showUploadHistoryModal,
    handleUploadHistoryShow,
    handleUploadHistoryClose,
    historyFilesData,
    fileHistoryListArr,
    toViewHistory,
    handleViewHistory,
    fileListArr,
    filesData,
    hiddenFileInput,
    onInputClick,
    handleUpload,
    handleChange,
    showErrorScreen,
    handleShowError,
    file,
    handleDownload,
  } = useDataSubmit();

  return (
    <div className={cx(styles.container)}>
      {!showErrorScreen ? (
        <>
          <div className={cx(styles.headerRow)}>
            <span className={cx(styles.headerTitle)}>
              {toViewHistory ? "Upload History" : "Submit Files"}
            </span>

            <span className={cx(styles.rightButtons)}>
              <button
                className={
                  toViewHistory
                    ? cx(styles.viewHistoryBtn, styles.viewHistoryBtnActive)
                    : cx(styles.viewHistoryBtn)
                }
                onClick={handleViewHistory}
              >
                <Icon name="download_hist" />
                {toViewHistory ? "Close Upload History" : "View Upload History"}
              </button>

              <button
                className={cx(styles.downloadTemplateBtn)}
                onClick={handleDownloadShow}
              >
                <Icon name="eye" />
                Download Data Templates
              </button>
            </span>
            <DownloadFileModal
              show={showDownloadModal}
              handleClose={handleDownloadClose}
            />
            <UploadFileModal
              show={showUploadModal}
              handleClose={handleUploadClose}
              data={filesData}
            />
            <UploadHistoryModal
              show={showUploadHistoryModal}
              handleClose={handleUploadHistoryClose}
              data={historyFilesData}
            />
          </div>

          <div className={cx(styles.content)}>
            {toViewHistory
              ? fileHistoryListArr.map((filesObj) => (
                  <div className={cx(styles.card)} key={filesObj.id}>
                    <div className={cx(styles.action)}>
                      <span className={cx(styles.cardTitleLbl)}>
                        {filesObj.name}
                      </span>
                      <button
                        className={cx(styles.submitBtn)}
                        onClick={() => handleUploadHistoryShow(filesObj)}
                      >
                        View History
                      </button>
                    </div>
                    <div className={cx(styles.files)}>
                      {filesObj.files.map((file, index) => (
                        <div key={file.id}>
                          {index ? <hr className={cx(styles.hrLine)} /> : null}
                          <div className={cx(styles.file)} key={file.id}>
                            <span className={cx(styles.fileLbl)}>
                              {file.name}
                            </span>

                            {file.state === "in_progress" && (
                              <span className={cx(styles.inProgress)}>
                                <Loader />
                              </span>
                            )}
                            {file.state === "no_data" && (
                              <span className={cx(styles.accepted)}>
                                No History
                              </span>
                            )}
                          </div>

                          {file.historyList.length === 0 ? null : (
                            <table className={cx(styles.histTable)}>
                              <thead>
                                <tr>
                                  {hfCol.map((col, index) => (
                                    <th key={index}>{col}</th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {file.historyList.map((hf, index) => (
                                  <tr key={index}>
                                    <td>{hf.file}</td>
                                    <td>{hf.creator_name}</td>
                                    <td>{hf.created_at}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ))
              : fileListArr.map((filesObj) => (
                  <div className={cx(styles.card)} key={filesObj.id}>
                    <div className={cx(styles.action)}>
                      <span className={cx(styles.cardTitleLbl)}>
                        {filesObj.name}
                      </span>
                      <button
                        className={cx(styles.submitBtn)}
                        onClick={() => handleUploadShow(filesObj)}
                      >
                        Submit
                      </button>
                    </div>
                    <div className={cx(styles.files)}>
                      {filesObj.files.map((file, index) => (
                        <div key={file.id}>
                          {index ? <hr className={cx(styles.hrLine)} /> : null}
                          <div className={cx(styles.file)} key={file.id}>
                            <div className={cx(styles.flex)}>
                              <span onClick={() => handleDownload(file.id)}>
                                <Icon name="download" />
                              </span>
                              <span className={cx(styles.fileLbl)}>
                                {file.name}
                              </span>
                            </div>
                            {file.state === "in_progress" && (
                              <span className={cx(styles.inProgress)}>
                                <Loader />
                              </span>
                            )}
                            {file.state === "accepted" && (
                              <span className={cx(styles.accepted)}>
                                Data Submitted
                              </span>
                            )}
                            {file.state === "error" && (
                              <span className={cx(styles.error)}>
                                <span className={cx(styles.errorLbl)}>
                                  File not accepted:
                                </span>
                                <div
                                  className={cx(styles.errorBox)}
                                  onClick={() => handleShowError(true, file)}
                                >
                                  <Icon name="error_16" />
                                  <span>View Data Errors</span>
                                </div>
                                <input
                                  type="file"
                                  accept=".csv"
                                  ref={(element) =>
                                    (hiddenFileInput.current[index] = element)
                                  }
                                  onChange={(e) => handleChange(e, file)}
                                  onClick={onInputClick}
                                  style={{ display: "none" }}
                                />
                                <button
                                  onClick={() => handleUpload(index)}
                                  className={cx(styles.resubmitBtn)}
                                >
                                  Resubmit
                                  <Icon name="redo" />
                                </button>
                              </span>
                            )}
                            {file.state === "incorrect" && (
                              <span className={cx(styles.error)}>
                                <span className={cx(styles.errorLbl)}>
                                  File not accepted: {file.error.message}
                                </span>
                              </span>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
          </div>
        </>
      ) : (
        <>
          <div>
            <span
              className={cx(styles.back)}
              onClick={() => handleShowError(false, {})}
            >
              <Icon name="back" />
            </span>
            <div className={cx(styles.headerTitle)}>
              Data Quality Checker Report: {file.name}
            </div>
            <div
              className="ag-theme-alpine"
              style={{
                height: "450px",
                width: "100%",
                padding: "1rem 0 0 0",
              }}
            >
              <AgGridReact
                columnDefs={file.error.columnDefs}
                rowData={file.error.rowData}
                pagination={false}
                paginationAutoPageSize={false}
                defaultColDef={{
                  editable: false,
                  sortable: true,
                  resizable: true,
                  flex: 1,
                  minWidth: 100,
                }}
              ></AgGridReact>
            </div>
            <div className={cx(styles.flex, styles.legCon)}>
              <div className={cx(styles.flex, styles.legBox)}>
                <div
                  className={cx(styles.legendBox)}
                  style={{ background: "#FF7070" }}
                ></div>
                <div className={cx(styles.legendLabel)}>Missing data value</div>
              </div>
              <div className={cx(styles.flex, styles.legBox)}>
                <div
                  className={cx(styles.legendBox)}
                  style={{ background: "#FFF388" }}
                ></div>
                <div className={cx(styles.legendLabel)}>Incorrect format</div>
              </div>
              <div className={cx(styles.flex, styles.legBox)}>
                <div
                  className={cx(styles.legendBox)}
                  style={{ background: "#A7C0E9" }}
                ></div>
                <div className={cx(styles.legendLabel)}>Inconsistent data</div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DataSubmit;
