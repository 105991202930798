import { Modal } from "react-bootstrap";
import "../../Form.css";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./UploadHistoryModal.module.css";
import Icon from "../Icon/Icon";
import useUploadHistoryModal from "./useUploadHistoryModal";
import cx from "classnames";

const UploadHistoryModal = ({ show, handleClose, data }) => {
  const { handleUploadHistory } = useUploadHistoryModal(handleClose);
  return (
    <>
      {data && (
        <Modal
          show={show}
          backdrop="static"
          keyboard={false}
          dialogClassName={cx(styles.minWidth)}
          className="modal"
          backdropClassName="modal-backdrop"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className={cx(styles.titleUpl)}>
            <label className={cx(styles.titleUplLbl)}>{data.name}</label>
            <span onClick={handleClose} className={cx(styles.closeBtn)}>
              <Icon name="close"></Icon>
            </span>
          </div>

          <div className={cx(styles.content)}>
            {data.files.map((file, index) => (
              <div className={cx(styles.card)} key={file.id}>
                <span className={cx(styles.cardUplLbl)}>{file.name}</span>
                <button
                  className={cx(styles.submitBtn)}
                  onClick={() => handleUploadHistory(index, file.id)}
                >
                  View
                </button>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default UploadHistoryModal;
