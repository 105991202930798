import cx from "classnames";
import styles from "./Login.module.css";
import "../../Form.css";
import { Carousel, Footer, Title, Icon, ErrorTooltip } from "../../components";
import square from "../../assets/icons/square_blue.svg";
// import Recaptcha from "react-recaptcha";
import { Link } from "react-router-dom";
import useLogin from "./useLogin";

export const Login = () => {
  const {
    captchaSiteKey,
    handleChange,
    handleSubmit,
    verifyCallback,
    expiredCallback,
    form,
    errors,
    loading,
  } = useLogin();

  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.flexChild)}>
        <div className={cx(styles.content)}>
          <div className={cx(styles.outerContent)}>
            <Title title="Keep Your Account" subTitle="Secure" />
            <form onSubmit={handleSubmit}>
              <div className={cx(styles.form)}>
                <div className={cx(styles.inputForm)}>
                  {errors.username && (
                    <div className="error-icon">
                      <ErrorTooltip
                        title={errors.username}
                        placement="right"
                        interactive
                      >
                        <div>
                          <Icon name="error" />
                        </div>
                      </ErrorTooltip>
                    </div>
                  )}
                  <label htmlFor="username" className="form-label">
                    Username
                  </label>
                  <input
                    id="username"
                    type="text"
                    name="username"
                    className={cx("form-input", {
                      "form-input-error": errors.username,
                    })}
                    value={form.username}
                    onChange={handleChange}
                  />
                </div>
                <div className={cx(styles.inputForm)}>
                  {errors.password && (
                    <div className="error-icon">
                      <ErrorTooltip
                        title={errors.password}
                        placement="right"
                        interactive
                      >
                        <div>
                          <Icon name="error" />
                        </div>
                      </ErrorTooltip>
                    </div>
                  )}
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    id="password"
                    type="password"
                    name="password"
                    className={cx("form-input", {
                      "form-input-error": errors.password,
                    })}
                    value={form.password}
                    onChange={handleChange}
                  />
                </div>
                {/* <div className={cx(styles.reCaptcha)}>
                  <Recaptcha
                    sitekey={captchaSiteKey}
                    render="explicit"
                    verifyCallback={verifyCallback}
                    expiredCallback={expiredCallback}
                  />
                </div> */}
                <div className={cx(styles.action)}>
                  <button
                    disabled={loading}
                    className="form-input-btn"
                    type="submit"
                  >
                    Log in
                  </button>
                  <span>
                    <Link
                      className="form-label form-padding-left-20px"
                      to="/forgot-password"
                    >
                      Forgot password?
                    </Link>
                  </span>
                </div>
              </div>
              <div className={cx(styles.register)}>
                <img
                  className="form-padding-left-20px"
                  alt="square"
                  src={square}
                />
                <div className="form-padding-left-20px form-padding-right-20px">
                  <div>
                    <Link className={cx(styles.reglink)} to="/register">
                      Register with FTG Admin
                    </Link>
                    <p className={cx(styles.reginfo)}>
                      Get access to student performance, insights and more
                    </p>
                    <p className={cx(styles.reginfo)}>
                      Contact{" "}
                      <a
                        className={cx(styles.reginfo)}
                        href="https://webadmin@fortunetimesgroup.com"
                      >
                        FTG Admin
                      </a>{" "}
                      for any queries
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
      <div className={cx(styles.flexChild)}>
        <Carousel />
      </div>
    </div>
  );
};

export default Login;
