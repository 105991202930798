import React, { useContext } from "react";
import Icon from "../Icon/Icon";
import { GlobalContext } from "../../context/provider";

const useSidebar = () => {
  const {
    activeMenuState: { activeMenu },
    activeMenuDispatch,
  } = useContext(GlobalContext);

  const handleChange = (activeId) => {
    activeMenuDispatch({ type: activeId });
  };

  const sidebarData = [
    {
      id: "all",
      title: "All Files",
      icon: <Icon name="all" />,
      activeIcon: <Icon name="all_active" />,
    },
    {
      id: "acp",
      title: "Articulated Credit\nPrograms",
      icon: <Icon name="acp" />,
      activeIcon: <Icon name="acp_active" />,
    },
    {
      id: "sd",
      title: "Student\nDemographic",
      icon: <Icon name="sd" />,
      activeIcon: <Icon name="sd_active" />,
    },
    {
      id: "ap",
      title: "Academic\nPerformance",
      icon: <Icon name="ap" />,
      activeIcon: <Icon name="ap_active" />,
    },
    {
      id: "pp",
      title: "Post Program\n(1-Year)",
      icon: <Icon name="pp" />,
      activeIcon: <Icon name="pp_active" />,
    },
    {
      id: "bda",
      title: "Benchmark Data\nAnalysis",
      icon: <Icon name="bda" />,
      activeIcon: <Icon name="bda_active" />,
    },
  ];
  return { activeMenu, sidebarData, handleChange };
};

export default useSidebar;
