import {
  program_id_chk,
  program_name_chk,
  course_code_chk,
  course_name_chk,
} from "./field-chk-methods";

export const acpUdcCcPiPColDefsMap = {
  Program_ID: {
    headerName: "Program_ID",
    cellStyle: (params) => program_id_chk(params),
  },
  Program_Name: {
    headerName: "Program_Name",
    cellStyle: (params) => program_name_chk(params),
  },
  Course_ID: {
    headerName: "Course_ID",
    cellStyle: (params) => program_id_chk(params),
  },
  Course_Code: {
    headerName: "Course_Code",
    cellStyle: (params) => course_code_chk(params),
  },
  Course_Name: {
    headerName: "Course_Name",
    cellStyle: (params) => course_name_chk(params),
  },
};
