import React, { Fragment } from "react";
import cx from "classnames";
import styles from "./DataReporting.module.css";
import useDataReporting from "./useDataReporting";
import {
  formatListInitialState,
  semesterListInitialState,
  yearListInitialState,
} from "../../assets/constants";

const DataReporting = () => {
  const { reportListArr, handleGenerateReport, handleChange } =
    useDataReporting();
  const formatList = formatListInitialState;
  const semesterList = semesterListInitialState;
  const yearList = yearListInitialState;

  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.headerRow)}>
        <span className={cx(styles.headerTitle)}>Generate Final Report</span>
      </div>

      <div className={cx(styles.content)}>
        {reportListArr.map((reportObj) => (
          <div className={cx(styles.card)} key={reportObj.type}>
            <div className={cx(styles.action)}>
              <span className={cx(styles.cardTitleLbl)}>{reportObj.name}</span>

              <div className={cx(styles.flex)}>
                {reportObj.type === "acoor" ? (
                  <Fragment>
                    <span>
                      <select
                        className={cx(styles.dropDown, styles.dropDownSem)}
                        value={reportObj.from.semster}
                        onChange={(e) =>
                          handleChange(e, reportObj, "from_semester")
                        }
                      >
                        {semesterList.map((sem) => (
                          <option value={sem} key={sem}>
                            {sem}
                          </option>
                        ))}
                      </select>
                      <select
                        className={cx(styles.dropDown, styles.dropDownYear)}
                        value={reportObj.from.year}
                        onChange={(e) =>
                          handleChange(e, reportObj, "from_year")
                        }
                      >
                        {yearList.map((yr) => (
                          <option value={yr} key={yr}>
                            {yr}
                          </option>
                        ))}
                      </select>
                    </span>
                    <span className={cx(styles.toBtwDropDown)}>to</span>
                  </Fragment>
                ) : null}

                <span>
                  <select
                    className={cx(styles.dropDown, styles.dropDownSem)}
                    value={reportObj.to.semster}
                    disabled={reportObj.type === "acoor" ? false : true}
                    onChange={(e) => handleChange(e, reportObj, "to_semester")}
                  >
                    {semesterList.map((sem) => (
                      <option value={sem} key={sem}>
                        {sem}
                      </option>
                    ))}
                  </select>
                  <select
                    className={cx(styles.dropDown, styles.dropDownYear)}
                    value={reportObj.to.year}
                    onChange={(e) => handleChange(e, reportObj, "to_year")}
                  >
                    {yearList.map((yr) => (
                      <option value={yr} key={yr}>
                        {yr}
                      </option>
                    ))}
                  </select>
                </span>
                <span>
                  <select
                    className={cx(styles.dropDown, styles.dropDownFormat)}
                    value={reportObj.format}
                    onChange={(e) => handleChange(e, reportObj, "format")}
                  >
                    {formatList.map((fmt) => (
                      <option value={fmt} key={fmt}>
                        {fmt}
                      </option>
                    ))}
                  </select>
                </span>
                <button
                  className={cx(styles.generateBtn)}
                  onClick={() =>
                    handleGenerateReport(
                      reportObj.type,
                      reportObj.from.semester + " " + reportObj.from.year,
                      reportObj.to.semester + " " + reportObj.to.year,
                      reportObj.format
                    )
                  }
                >
                  Generate
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DataReporting;
