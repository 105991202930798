import {
  high_school_id_chk,
  school_name_chk,
  school_district_name_chk,
  ward_chk,
  city_chk,
  state_chk,
  // county_chk,
  school_type_chk,
  program_id_chk,
} from "./field-chk-methods";

export const apHsiHsColDefsMap = {
  School_Name: {
    headerName: "School_Name",
    cellStyle: (params) => school_name_chk(params),
  },
  High_School_ID: {
    headerName: "High_School_ID",
    cellStyle: (params) => high_school_id_chk(params),
  },
  School_District_Name: {
    headerName: "School_District_Name",
    cellStyle: (params) => school_district_name_chk(params),
  },
  Ward: { headerName: "Ward", cellStyle: (params) => ward_chk(params) },
  City: { headerName: "City", cellStyle: (params) => city_chk(params) },
  State: { headerName: "State", cellStyle: (params) => state_chk(params) },
  School_Type: {
    headerName: "School_Type",
    cellStyle: (params) => school_type_chk(params),
  },
  Program_ID: {
    headerName: "Program_ID",
    cellStyle: (params) => program_id_chk(params),
  },
};
