export const REG_LOADING = "REG_LOADING";
export const REG_SUCCESS = "REG_SUCCESS";
export const REG_ERROR = "REG_ERROR";
export const RESET = "RESET";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const FORGOT_PASSWORD_LOADING = "FORGOT_PASSWORD_LOADING";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const SEND_EMAIL_LOADING = "SEND_EMAIL_LOADING";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_ERROR = "SEND_EMAIL_ERROR";
export const SEND_OTP_LOADING = "SEND_OTP_LOADING";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_ERROR = "SEND_OTP_ERROR";
export const VERIFY_EMAIL_LOADING = "VERIFY_EMAIL_LOADING";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_ERROR = "VERIFY_EMAIL_ERROR";
export const VERIFY_OTP_LOADING = "VERIFY_OTP_LOADING";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_ERROR = "VERIFY_OTP_ERROR";
export const UPLOAD_LOADING = "UPLOAD_LOADING";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_ERROR = "UPLOAD_ERROR";
export const GRID_ERROR = "GRID_ERROR";
export const GRID_INCORRECT = "GRID_INCORRECT";
export const FILE_STATUS_LOADING = "FILE_STATUS_LOADING";
export const SET_FILE_STATUS = "SET_FILE_STATUS";
export const FILE_STATUS_ERROR = "FILE_STATUS_ERROR";
export const VERIFY_DB_LOADING = "VERIFY_DB_LOADING";
export const VERIFY_DB_SUCCESS = "VERIFY_DB_SUCCESS";
export const VERIFY_DB_ERROR = "VERIFY_DB_ERROR";
export const VERIFY_DB_GRID_ERROR = "VERIFY_DB_GRID_ERROR";
export const VERIFY_DB_NO_CHECK = "VERIFY_DB_NO_CHECK";
export const REPORT_SELECT_CHANGE = "REPORT_SELECT_CHANGE";
export const USER_PROFILE_LOADING = "USER_PROFILE_LOADING";
export const USER_PROFILE_SET = "USER_PROFILE_SET";
export const USER_PROFILE_UPDATE = "USER_PROFILE_UPDATE";
export const USER_PROFILE_ERROR = "USER_PROFILE_ERROR";
export const USER_PROFILE_RESET = "USER_PROFILE_RESET";
export const UPLOAD_HISTORY_LOADING = "UPLOAD_HISTORY_LOADING";
export const UPLOAD_HISTORY_SUCCESS = "UPLOAD_HISTORY_SUCCESS";
export const UPLOAD_HISTORY_ERROR = "UPLOAD_HISTORY_ERROR";
