import cx from "classnames";
import styles from "./ValidateOtp.module.css";
import "../../Form.css";
import { Carousel, Footer, Title, Icon } from "../../components";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import useValidateOtp from "./useValidateOtp";

export const ValidateOtp = () => {
  const {
    mobileNo,
    otp,
    otpError,
    lblText,
    handleChange,
    handleSubmit,
    handleResend,
    loading,
  } = useValidateOtp();

  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.flexChild)}>
        <div className={cx(styles.content)}>
          <div className={cx(styles.outerContent)}>
            <div className={cx(styles.backArrow)}>
              <Link to='/validate'>
                <Icon name='back' />
              </Link>
            </div>
            <Title title='Keep Your Account' subTitle='Secure' />
            <form onSubmit={handleSubmit}>
              <div className={cx(styles.form)}>
                <label className={cx(styles.lblNotify)}>
                  We have sent a verification code to{" "}
                  <b className='font-weight-500'>{mobileNo}</b>
                </label>
                <div className={cx(styles.marginTop)}>
                  <label
                    className={cx(styles.lblNotify, {
                      [styles.lblNotifyError]: otpError === true,
                    })}
                  >
                    {lblText}
                  </label>
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    shouldAutoFocus={true}
                    isInputNum={true}
                    numInputs={5}
                    containerStyle={cx(styles.containerStyle)}
                    inputStyle={cx(styles.inputStyle, {
                      [styles.inputStyleError]: otpError === true,
                    })}
                    separator={<span className={cx(styles.mr)}></span>}
                    // hasErrored= {false}
                  />
                </div>
                <div className={cx(styles.marginTop)}>
                  <label className='form-label-gray'>
                    Didn't receive code?{" "}
                  </label>
                  <a className='form-label-link' onClick={handleResend}>
                    Resend
                  </a>
                </div>
              </div>
              <div className={cx(styles.action)}>
                <button
                  disabled={loading}
                  className='form-input-btn'
                  type='submit'
                >
                  Verify
                </button>
              </div>
            </form>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
      <div className={cx(styles.flexChild)}>
        <Carousel />
      </div>
    </div>
  );
};

export default ValidateOtp;
