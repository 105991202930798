import { useState, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import maskEmailer from "../../utils/maskEmailer";
import { sendOtpReducer, sendEmailReducer } from "../../context/reducer";
import {
  sendOtpInitialState,
  sendEmailInitialState,
} from "../../context/initialState";
import { sendOtpApi, sendEmailApi } from "../../context/actions";
import { RESET } from "../../constants/actionTypes";

const useValidate = () => {
  const history = useHistory();
  const { addToast } = useToasts();

  const [authMode, setAuthMode] = useState("email");
  const mobileNo = localStorage.mobileNo;
  const email = localStorage.email;
  const maskEmail = maskEmailer(email);
  const [loader, setLoader] = useState(false);

  const [{ payload: otpPayload, loading: otpLoader }, otpDispatch] = useReducer(
    sendOtpReducer,
    sendOtpInitialState
  );

  const [{ payload: emailPayload, loading: emailLoader }, emailDispatch] =
    useReducer(sendEmailReducer, sendEmailInitialState);

  const handleChange = (event) => {
    setAuthMode(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (authMode === "email") {
      sendEmailApi({ email: email })(emailDispatch);
    } else if (authMode === "otp") {
      sendOtpApi({ mobile_no: mobileNo })(otpDispatch);
    }
  };

  useEffect(() => {
    if (otpLoader || emailLoader) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [otpLoader, emailLoader]);

  useEffect(() => {
    if (emailPayload) {
      if (emailPayload.success) {
        history.push("/validate-email");
      }
      addToast(emailPayload.msg, {
        appearance: emailPayload.success ? "success" : "error",
        autoDismiss: true,
      });
    }
    return () => {
      emailDispatch({
        type: RESET,
      });
    };
  }, [emailPayload]);

  useEffect(() => {
    if (otpPayload) {
      if (otpPayload.success) {
        history.push("/validate-otp");
      }
      addToast(otpPayload.msg, {
        appearance: otpPayload.success ? "success" : "error",
        autoDismiss: true,
      });
    }
    return () => {
      otpDispatch({
        type: RESET,
      });
    };
  }, [otpPayload]);

  return {
    maskEmail,
    mobileNo,
    authMode,
    handleChange,
    handleSubmit,
    loader,
  };
};

export default useValidate;
