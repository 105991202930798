import {
  career_clusters_chk,
  global_chk,
  student_id_chk,
  unemployed_chk,
} from "./field-chk-methods";

export const ppPpsiColDefsMap = {
  Student_ID: {
    headerName: "Student_ID",
    cellStyle: (params) => student_id_chk(params),
  },
  Company_Name: {
    headerName: "Company_Name",
    cellStyle: (params) => global_chk(params),
  },
  Career_Clusters: {
    headerName: "Career_Clusters",
    cellStyle: (params) => career_clusters_chk(params),
  },
  Hourly_Wages: {
    headerName: "Hourly_Wages",
    cellStyle: (params) => global_chk(params),
  },
  School_Name: {
    headerName: "School_Name",
    cellStyle: (params) => global_chk(params),
  },
  Major_Category: {
    headerName: "Major_Category",
    cellStyle: (params) => global_chk(params),
  },
  Degree: {
    headerName: "Degree",
    cellStyle: (params) => global_chk(params),
  },
  Unemployed: {
    headerName: "Unemployed",
    cellStyle: (params) => unemployed_chk(params),
  },
};
