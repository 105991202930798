import cx from 'classnames'
import styles from './Carousel.module.css'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

export const CarouselComponent = () => {
    return (
        <div className={cx(styles.container)}>
            <Carousel className={cx(styles.carousel)} autoPlay useKeyboardArrows showThumbs={false} showStatus={false} showArrows={false}>
                <div className={cx(styles.content)}>
                </div>
                <div className={cx(styles.content)}>
                </div>
                <div className={cx(styles.content)}>
                </div>
            </Carousel>
        </div>
    )
}

export default CarouselComponent