export default function validateLogin(values) {
  let errors = {};

  if (!values.username.trim()) {
    errors.username = "Username is required.";
  }

  if (!values.password.trim()) {
    errors.password = "Password is required.";
  } else if (values.password.length < 8) {
    errors.password = "Password must be 8 characters or more.";
  }

  return errors;
}
