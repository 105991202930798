import { useEffect, useReducer, useState } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { getUserProfile, setUserProfile } from "../../context/actions";
import { userProfileReducer } from "../../context/reducer";
import { userProfileInitialState } from "../../context/initialState";
import { USER_PROFILE_RESET } from "../../constants/actionTypes";

const useUserProfile = () => {
  const history = useHistory();
  const { addToast } = useToasts();

  const [{ formValue, payload, loading }, userProfileDispatch] = useReducer(
    userProfileReducer,
    userProfileInitialState
  );
  const [makeChangeFlag, setMakeChangeFlag] = useState(false);

  const [form, setFormValues] = useState(formValue);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getUserProfile()(userProfileDispatch);
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  useEffect(() => {
    setFormValues(formValue);
  }, [formValue]);

  useEffect(() => {
    if (payload) {
      addToast(payload.msg, {
        appearance: payload.success ? "success" : "error",
        autoDismiss: true,
      });
    }
    return () => {
      userProfileDispatch({
        type: USER_PROFILE_RESET,
      });
    };
  }, [payload]);

  const handleBackClick = (e) => {
    // e.preventDefault();
    history.push("/");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...form,
      [name]: { value: value, updated: true },
    });
  };

  const handleMakeChanges = (e) => {
    e.preventDefault();
    setMakeChangeFlag(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setUserProfile(form)(userProfileDispatch);
  };

  return {
    form,
    loader,
    makeChangeFlag,
    handleBackClick,
    handleChange,
    handleMakeChanges,
    handleSubmit,
  };
};

export default useUserProfile;
