import { GLOBAL_CONST } from "../../assets/constants";
import {
  colorCode,
  sanitiseData,
  completenessChk,
  typeConformityChk,
  isRegExMatch,
} from "./common";
import { city_chk, state_chk, student_id_chk } from "./field-chk-methods";

const name_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  if (params.value[0] !== params.value[0].toUpperCase()) {
    return { "background-color": colorCode.yellow };
  }
};

const gender_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  const genderList = ["Female", "Male", "Other"];
  if (!genderList.includes(params.value)) {
    return { "background-color": colorCode.yellow };
  }
};

const phone_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let phoneRegEx = /^\d{3}-\d{3}-\d{4}$/;
  if (!isRegExMatch(params.value, phoneRegEx)) {
    return { "background-color": colorCode.yellow };
  }
};

const primary_email_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let floatRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.edu$/;
  if (!params.value.match(floatRegex)) {
    return { "background-color": colorCode.yellow };
  }
};

const personal_email_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let floatRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+$/;
  if (!params.value.match(floatRegex)) {
    return { "background-color": colorCode.yellow };
  }
};

const race_ethnicity_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  const raceEthnicityList = [
    "White",
    "Black or African American",
    "Asian",
    "American Indian or Alaska Native",
    "Native Hawaiian or Pacific Islander",
    "Two or more races",
    "Hispanic or Latino",
    "Other",
  ];

  if (!raceEthnicityList.includes(params.value)) {
    return { "background-color": colorCode.yellow };
  }
};

const special_populations_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  const specialPopulationsList = [
    "Individuals with disabilities",
    "Individuals with disabilities: ADA",
    "Individuals with disabilities: IDEA",
    "Economically disadvantaged",
    "Nontraditional fields",
    "Single parents",
    "Out of workforce",
    "English learners",
    "Homeless individuals",
    "Youth in foster care",
    "Youth with a parent in active military",
    "Migrant students",
  ];
  if (!specialPopulationsList.includes(params.value)) {
    return { "background-color": colorCode.yellow };
  }
};

const street_number_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let typeChkRes = typeConformityChk(params.value, "integer");
  if (typeChkRes) {
    return typeChkRes;
  }
};

const street_name_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }
};

const home_zip_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let zipRegEx = /^\d{5}$/;
  if (!isRegExMatch(params.value, zipRegEx)) {
    return { "background-color": colorCode.yellow };
  }
};

const grade_level_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let typeChkRes = typeConformityChk(params.value, "integer");
  if (typeChkRes) {
    return typeChkRes;
  }
};

const date_of_enrollment_chk = (params) => {
  params.value = sanitiseData(params.value);

  let completenessChkRes = completenessChk(params.value);
  if (completenessChkRes) {
    return completenessChkRes;
  } else if (params.value === "NA") {
    return { "background-color": colorCode.red };
  }

  let dateSplit = params.value.split(" ");
  if (dateSplit.length !== 3) {
    return { "background-color": colorCode.yellow };
  }
  // check month
  let monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  if (!monthList.includes(dateSplit[0])) {
    return { "background-color": colorCode.yellow };
  }
  // check date
  let dateComma = dateSplit[1].slice(-1);
  if (dateComma !== ",") {
    return { "background-color": colorCode.yellow };
  }

  dateSplit[1] = dateSplit[1].slice(0, -1);
  let dateRegex = /^\d{1,2}$/;
  if (!dateSplit[1].match(dateRegex)) {
    return { "background-color": colorCode.yellow };
  }

  // check year
  let yearRegex = /^\d{4}$/;
  if (!dateSplit[2].match(yearRegex) || dateSplit[2] < GLOBAL_CONST.MIN_YEAR) {
    return { "background-color": colorCode.yellow };
  }
};

export const sdSiColDefsMap = {
  Student_ID: {
    headerName: "Student_ID",
    cellStyle: (params) => student_id_chk(params),
  },
  First_Name: {
    headerName: "First_Name",
    cellStyle: (params) => name_chk(params),
  },
  Last_Name: {
    headerName: "Last_Name",
    cellStyle: (params) => name_chk(params),
  },
  Gender: {
    headerName: "Gender",
    cellStyle: (params) => gender_chk(params),
  },
  Phone: {
    headerName: "Phone",
    cellStyle: (params) => phone_chk(params),
  },
  Primary_Email: {
    headerName: "Primary_Email",
    cellStyle: (params) => primary_email_chk(params),
  },
  Personal_Email: {
    headerName: "Personal_Email",
    cellStyle: (params) => personal_email_chk(params),
  },
  Race_Ethnicity: {
    headerName: "Race_Ethnicity",
    cellStyle: (params) => race_ethnicity_chk(params),
  },
  Special_Populations: {
    headerName: "Special_Populations",
    cellStyle: (params) => special_populations_chk(params),
  },
  Street_Number: {
    headerName: "Street_Number",
    cellStyle: (params) => street_number_chk(params),
  },
  Street_Name: {
    headerName: "Street_Name",
    cellStyle: (params) => street_name_chk(params),
  },
  City: {
    headerName: "City",
    cellStyle: (params) => city_chk(params),
  },
  State: {
    headerName: "State",
    cellStyle: (params) => state_chk(params),
  },
  Home_Zip: {
    headerName: "Home_Zip",
    cellStyle: (params) => home_zip_chk(params),
  },
  Parent_Name: {
    headerName: "Parent_Name",
    cellStyle: (params) => name_chk(params),
  },
  Parent_Number: {
    headerName: "Parent_Number",
    cellStyle: (params) => phone_chk(params),
  },
  Grade_Level: {
    headerName: "Grade Level",
    cellStyle: (params) => grade_level_chk(params),
  },
  Date_of_Enrollment: {
    headerName: "Date_of_Enrollment",
    cellStyle: (params) => date_of_enrollment_chk(params),
  },
  Date_of_Graduation: {
    headerName: "Date_of_Graduation",
    cellStyle: (params) => date_of_enrollment_chk(params),
  },
};
