import { useState, useEffect, useReducer } from "react";
import { resetPasswordApi } from "../../context/actions";
import { useLocation, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { RESET } from "../../constants/actionTypes";
import validate from "./validateResetPassword";
import { resetPasswordReducer } from "../../context/reducer";
import { resetPasswordInitialState } from "../../context/initialState";

const useResetPassword = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [form, setValues] = useState({
    password: "",
    confPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const token = query.get("token");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(form));
    setIsSubmitted(true);
  };

  const [{ payload, loading }, dispatch] = useReducer(
    resetPasswordReducer,
    resetPasswordInitialState
  );

  useEffect(() => {
    // if (Object.keys(errors).length === 0 && isSubmitted && isVerified) {
    if (Object.keys(errors).length === 0 && isSubmitted) {
      resetPasswordApi({
        password: form.password,
        token: token,
      })(dispatch);
    }
  }, [errors]);

  useEffect(() => {
    if (payload) {
      if (payload.success) {
        history.push("/login");
      }

      addToast(payload.msg, {
        appearance: payload.success ? "success" : "error",
        autoDismiss: true,
      });
    }
    return () => {
      dispatch({
        type: RESET,
      });
    };
  }, [payload]);

  return {
    handleChange,
    handleSubmit,
    form,
    errors,
    loading,
  };
};

export default useResetPassword;
