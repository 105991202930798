import { useRef, useContext } from "react";
import { GRID_ERROR, GRID_INCORRECT } from "../../constants/actionTypes";
import { uploadHistoryApi } from "../../context/actions";
import { GlobalContext } from "../../context/provider";
import parser from "../../utils/parser";

const useUploadHistoryModal = (handleClose) => {
  const hiddenFileInput = useRef({});
  const onInputClick = (e) => {
    e.target.value = "";
  };

  const { fileHistoryList, fileHistoryListDispatch } =
    useContext(GlobalContext);

  const handleUploadHistory = async (index, fileId) => {
    handleClose();
    uploadHistoryApi(fileId)(fileHistoryListDispatch);
  };
  const { fileList, fileListDispatch } = useContext(GlobalContext);

  const handleChange = async (e, file) => {
    const fileUploaded = e.target.files[0];
    //1. pass file object to parser
    const { success, gridError } = await parser(fileUploaded, file);
    handleClose();
    // reset previous errors
    if (success) {
      uploadHistoryApi(file.id)(fileListDispatch);
      //2. if no errors call upload api
    } else {
      if (gridError.columnDefs) {
        //3. update fileList with columnDefs and rowData
        fileListDispatch({
          type: GRID_ERROR,
          fileId: file.id,
          gridError,
        });
      } else {
        fileListDispatch({
          type: GRID_INCORRECT,
          fileId: file.id,
          gridError,
        });
      }
    }
  };
  return {
    fileList,
    hiddenFileInput,
    onInputClick,
    handleUploadHistory,
    handleChange,
  };
};

export default useUploadHistoryModal;
