import { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import { downloadReportApi } from "../../context/actions";
import { REPORT_SELECT_CHANGE } from "../../constants/actionTypes";

const useDataReporting = () => {
  const {
    activeMenuState: { activeMenu },
  } = useContext(GlobalContext);
  const { reportList, reportListDispatch } = useContext(GlobalContext);
  const [reportListArr, setreportListArr] = useState(reportList);

  useEffect(() => {
    setreportListArr(reportList);
  }, [activeMenu, reportList]);

  const handleGenerateReport = (reportType, fromDate, toDate, reportFormat) => {
    downloadReportApi(reportType, fromDate, toDate, reportFormat);
  };

  const handleChange = (e, reportObj, changeKey) => {
    reportListDispatch({
      type: REPORT_SELECT_CHANGE,
      reportType: reportObj.type,
      key: changeKey,
      value: e.target.value,
    });
  };

  return { reportListArr, handleChange, handleGenerateReport };
};

export default useDataReporting;
