import Papa from "papaparse";
import { columnDefsMap, isCsvError } from "../helpers/data-check/common";

const parser = async (fileUploaded, file) => {
  let rowData = [];

  function toJson(fileUploaded) {
    return new Promise((resolve, reject) => {
      Papa.parse(fileUploaded, {
        header: true,
        skipEmptyLines: true,
        complete(results) {
          resolve(results);
          // resolve(results.data);
        },
        error(err) {
          reject(err);
        },
      });
    });
  }

  let parsedData = await toJson(fileUploaded);
  let columnList = parsedData.meta.fields;
  rowData = parsedData.data;

  let success = true;
  let columnDefs = null;
  let errorMessage = null;

  if (columnList.length === 0 || rowData.length === 0) {
    success = false;
    errorMessage = "Empty file";
  } else if (columnList.length !== Object.keys(columnDefsMap[file.id]).length) {
    success = false;
    errorMessage = "Incorrect columns";
  }

  let { chkRowData, isFormatIncorrect } = isCsvError(rowData, file.id);

  if (isFormatIncorrect) {
    success = false;
    rowData = null;
    errorMessage = "Incorrect csv format.";
  } else if (chkRowData.length > 0) {
    success = false;
    columnDefs = [];

    for (let column of columnList) {
      if (columnDefsMap[file.id][column]) {
        let def = {
          headerName: columnDefsMap[file.id][column]["headerName"],
          field: column,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset", "apply"],
            closeOnApply: true,
          },
          cellStyle: (params) =>
            columnDefsMap[file.id][column]["cellStyle"](params),
        };

        columnDefs.push(def);
      }
    }
  }

  let gridError = {
    columnDefs: columnDefs,
    rowData: chkRowData,
    message: errorMessage,
  };

  return { success, gridError };
};

export default parser;
