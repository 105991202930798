import React from "react";
import useSidebar from "./useSidebar";
import styles from "./Sidebar.module.css";
import cx from "classnames";

const Sidebar = () => {
  const { activeMenu, sidebarData, handleChange } = useSidebar();

  return (
    <div className={cx(styles.sidebar)}>
      {sidebarData.map((menu, index) => (
        <div
          className={cx(styles.menu)}
          key={index}
          onClick={() => handleChange(menu.id)}
        >
          {activeMenu === menu.id ? (
            <span>{menu.activeIcon}</span>
          ) : (
            <span>{menu.icon}</span>
          )}
          <pre
            className={cx({
              [styles.active]: activeMenu === menu.id,
              [styles.notActive]: activeMenu !== menu.id,
            })}
          >
            {menu.title}
          </pre>
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
