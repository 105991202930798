import {
  formatListInitialState,
  semesterListInitialState,
  yearListInitialState,
} from "../assets/constants";

export const regInitialState = {
  loading: false,
  payload: null,
  error: null,
};

export const loginInitialState = {
  loading: false,
  payload: null,
  error: null,
};

export const forgotPasswordInitialState = {
  loading: false,
  payload: null,
  error: null,
};

export const resetPasswordInitialState = {
  loading: false,
  payload: null,
  error: null,
};

export const sendOtpInitialState = {
  loading: false,
  payload: null,
  error: null,
};

export const sendEmailInitialState = {
  loading: false,
  payload: null,
  error: null,
};

export const verifyOtpInitialState = {
  loading: false,
  payload: null,
  error: null,
};

export const verifyEmailInitialState = {
  loading: false,
  payload: null,
  error: null,
};

export const fileListInitialState = [
  {
    id: "acp",
    name: "Articulated Credit Programs",
    files: [
      {
        id: "acp-udc-cc-pi-p",
        name: "UDC-CC Program Information - Program",
        state: "initial",
        error: {},
      },
      {
        id: "acp-udc-cc-pi-s",
        name: "UDC-CC Program Information - Student",
        state: "initial",
        error: {},
      },
    ],
  },
  {
    id: "sd",
    name: "Student Demographic",
    files: [
      {
        id: "sd-si",
        name: "Student Information",
        state: "initial",
        error: {},
      },
      {
        id: "sd-sai",
        name: "Student Applicant Information",
        state: "initial",
        error: {},
      },
    ],
  },
  {
    id: "ap",
    name: "Academic Performance",
    files: [
      {
        id: "ap-hsi-hs",
        name: "High School Information - High School",
        state: "initial",
        error: {},
      },
      {
        id: "ap-hsi-s",
        name: "High School Information - Student",
        state: "initial",
        error: {},
      },
      {
        id: "ap-sgi",
        name: "Student GPA Information",
        state: "initial",
        error: {},
      },
      {
        id: "ap-sci",
        name: "Student Course Information",
        state: "initial",
        error: {},
      },
    ],
  },
  {
    id: "pp",
    name: "Post-Program (1-Year)",
    files: [
      {
        id: "pp-ppsi",
        name: "Post-Program Student Information",
        state: "initial",
        error: {},
      },
    ],
  },
  {
    id: "bda",
    name: "Benchmark Data Analysis",
    files: [
      {
        id: "bda-saps",
        name: "State-Approved Program Of Study (POS)",
        state: "initial",
        error: {},
      },
      {
        id: "bda-lim",
        name: "LMI Industry Metrics",
        state: "initial",
        error: {},
      },
    ],
  },
];

export const verifyFileListInitialState = [
  {
    id: "acp",
    name: "Articulated Credit Programs",
    files: [
      {
        id: "acp-udc-cc-pi-p",
        name: "UDC-CC Program Information - Program",
        state: "initial",
        error: {},
      },
      {
        id: "acp-udc-cc-pi-s",
        name: "UDC-CC Program Information - Student",
        state: "initial",
        error: {},
      },
    ],
  },
  {
    id: "sd",
    name: "Student Demographic",
    files: [
      {
        id: "sd-si",
        name: "Student Information",
        state: "initial",
        error: {},
      },
      {
        id: "sd-sai",
        name: "Student Applicant Information",
        state: "initial",
        error: {},
      },
    ],
  },
  {
    id: "ap",
    name: "Academic Performance",
    files: [
      {
        id: "ap-hsi-hs",
        name: "High School Information - High School",
        state: "initial",
        error: {},
      },
      {
        id: "ap-hsi-s",
        name: "High School Information - Student",
        state: "initial",
        error: {},
      },
      {
        id: "ap-sgi",
        name: "Student GPA Information",
        state: "initial",
        error: {},
      },
      {
        id: "ap-sci",
        name: "Student Course Information",
        state: "initial",
        error: {},
      },
    ],
  },
  {
    id: "pp",
    name: "Post-Program (1-Year)",
    files: [
      {
        id: "pp-ppsi",
        name: "Post-Program Student Information",
        state: "initial",
        error: {},
      },
    ],
  },
  {
    id: "bda",
    name: "Benchmark Data Analysis",
    files: [
      {
        id: "bda-saps",
        name: "State-Approved Program Of Study (POS)",
        state: "initial",
        error: {},
      },
      {
        id: "bda-lim",
        name: "LMI Industry Metrics",
        state: "initial",
        error: {},
      },
    ],
  },
];

export const activeMenuInitialState = {
  activeMenu: "all",
};

export const reportListInitialState = [
  {
    name: "Articulated Credit Overview for OSSE Report",
    type: "acoor",
    from: {
      semester: semesterListInitialState[0],
      year: yearListInitialState[0],
    },
    to: {
      semester: semesterListInitialState[0],
      year: yearListInitialState[0],
    },
    format: formatListInitialState[0],
    error: {},
  },
  {
    name: "CARE Enrollment at UDC and UDC-CC Report",
    type: "ceuur",
    from: {
      semester: semesterListInitialState[0],
      year: yearListInitialState[0],
    },
    to: {
      semester: semesterListInitialState[0],
      year: yearListInitialState[0],
    },
    format: formatListInitialState[0],
    error: {},
  },
  {
    name: "Articulated Credit Summary Report",
    type: "acsr",
    from: {
      semester: semesterListInitialState[0],
      year: yearListInitialState[0],
    },
    to: {
      semester: semesterListInitialState[0],
      year: yearListInitialState[0],
    },
    format: formatListInitialState[0],
    error: {},
  },
];

export const userProfileInitialState = {
  formValue: {
    // username: "",
    // email: "",
    // mobileNumber: "",
    // password: "",
    username: { value: "", updated: false },
    email: { value: "", updated: false },
    mobileNumber: { value: "", updated: false },
    password: { value: "", updated: false },
  },
  payload: null,
  loading: false,
};

export const fileHistoryListInitialState = [
  {
    id: "acp",
    name: "Articulated Credit Programs",
    files: [
      {
        id: "acp-udc-cc-pi-p",
        name: "UDC-CC Program Information - Program",
        historyList: [],
        state: "initial",
      },
      {
        id: "acp-udc-cc-pi-s",
        name: "UDC-CC Program Information - Student",
        historyList: [],
        state: "initial",
      },
    ],
  },
  {
    id: "sd",
    name: "Student Demographic",
    files: [
      {
        id: "sd-si",
        name: "Student Information",
        historyList: [],
        state: "initial",
      },
      {
        id: "sd-sai",
        name: "Student Applicant Information",
        historyList: [],
        state: "initial",
      },
    ],
  },
  {
    id: "ap",
    name: "Academic Performance",
    files: [
      {
        id: "ap-hsi-hs",
        name: "High School Information - High School",
        historyList: [],
        state: "initial",
      },
      {
        id: "ap-hsi-s",
        name: "High School Information - Student",
        historyList: [],
        state: "initial",
      },
      {
        id: "ap-sgi",
        name: "Student GPA Information",
        historyList: [],
        state: "initial",
      },
      {
        id: "ap-sci",
        name: "Student Course Information",
        historyList: [],
        state: "initial",
      },
    ],
  },
  {
    id: "pp",
    name: "Post-Program (1-Year)",
    files: [
      {
        id: "pp-ppsi",
        name: "Post-Program Student Information",
        historyList: [],
        state: "initial",
      },
    ],
  },
  {
    id: "bda",
    name: "Benchmark Data Analysis",
    files: [
      {
        id: "bda-saps",
        name: "State-Approved Program Of Study (POS)",
        historyList: [],
        state: "initial",
      },
      {
        id: "bda-lim",
        name: "LMI Industry Metrics",
        historyList: [],
        state: "initial",
      },
    ],
  },
];
