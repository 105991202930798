import { useState, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { sendOtpReducer, verifyOtpReducer } from "../../context/reducer";
import {
  sendOtpInitialState,
  verifyOtpInitialState,
} from "../../context/initialState";
import { sendOtpApi, verifyOtpApi } from "../../context/actions";
import { RESET } from "../../constants/actionTypes";
import axiosInstance from "../../helpers/axios";

const useValidateOtp = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [lblText, setLblText] = useState("Enter code");
  const mobileNo = localStorage.mobileNo;

  const [{ payload: otpPayload }, otpDispatch] = useReducer(
    sendOtpReducer,
    sendOtpInitialState
  );

  const [{ payload: verifyOtpPayload, loading }, verifyOtpDispatch] =
    useReducer(verifyOtpReducer, verifyOtpInitialState);

  const handleChange = (otp) => {
    setOtp(otp);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (otp.length !== 5) {
      setOtpError(true);
      return;
    }
    verifyOtpApi({ mobile_no: mobileNo, otp: otp })(verifyOtpDispatch);
  };

  const handleResend = (e) => {
    e.preventDefault();
    sendOtpApi({ mobile_no: mobileNo })(otpDispatch);
  };

  useEffect(() => {
    if (otpError) {
      setLblText("Incorrect code. Please re-enter.");
    } else {
      setLblText("Enter code");
    }
  }, [otpError]);

  useEffect(() => {
    if (otpPayload) {
      if (otpPayload.success) {
        setOtpError(false);
        // no success case, just show toast notification
      }
      addToast(otpPayload.msg, {
        appearance: otpPayload.success ? "success" : "error",
        autoDismiss: true,
      });
    }
    return () => {
      otpDispatch({
        type: RESET,
      });
    };
  }, [otpPayload]);

  useEffect(() => {
    if (verifyOtpPayload) {
      if (verifyOtpPayload.success) {
        localStorage.setItem("token", verifyOtpPayload.data.token);
        axiosInstance.defaults.headers["Authorization"] =
          "Bearer " + localStorage.getItem("token");

        history.push("/");
      }
      addToast(verifyOtpPayload.msg, {
        appearance: verifyOtpPayload.success ? "success" : "error",
        autoDismiss: true,
      });
      setOtpError(!verifyOtpPayload.success);
      setOtp("");
    }
    return () => {
      verifyOtpDispatch({
        type: RESET,
      });
    };
  }, [verifyOtpPayload]);

  return {
    mobileNo,
    otp,
    otpError,
    lblText,
    handleChange,
    handleSubmit,
    handleResend,
    loading,
  };
};

export default useValidateOtp;
