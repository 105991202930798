import cx from "classnames";
import styles from "./Validate.module.css";
import "../../Form.css";
import { Carousel, Footer, Title, Icon } from "../../components";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import useValidate from "./useValidate";

const CustomRadio = withStyles({
  root: {
    color: "#F9424E",
    "&$checked": {
      color: "#F9424E",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export const Validate = () => {
  const {
    maskEmail,
    mobileNo,
    authMode,
    handleChange,
    handleSubmit,
    loader: loading,
  } = useValidate();

  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.flexChild)}>
        <div className={cx(styles.content)}>
          <div className={cx(styles.outerContent)}>
            <div className={cx(styles.backArrow)}>
              <Link to="/login">
                <Icon name="back" />
              </Link>
            </div>
            <Title title="Keep Your Account" subTitle="Secure" />
            <form onSubmit={handleSubmit}>
              <div className={cx(styles.form)}>
                <div
                  className={cx(styles.flexCenter, styles.radioDiv, {
                    [styles.active]: authMode === "email",
                    [styles.notActive]: authMode !== "email",
                  })}
                >
                  <div className={cx(styles.paddingLeft)}>
                    <CustomRadio
                      checked={authMode === "email"}
                      onChange={handleChange}
                      id="email"
                      value="email"
                      name="auth-mode"
                      inputProps={{}}
                    />
                  </div>
                  <label htmlFor="email">
                    Send email verification to {maskEmail}{" "}
                  </label>
                </div>
                <div
                  className={cx(styles.flexCenter, styles.radioDiv, {
                    [styles.active]: authMode === "otp",
                    [styles.notActive]: authMode !== "otp",
                  })}
                >
                  <div className={cx(styles.paddingLeft)}>
                    <CustomRadio
                      checked={authMode === "otp"}
                      onChange={handleChange}
                      id="otp"
                      value="otp"
                      name="auth-mode"
                      inputProps={{}}
                    />
                  </div>
                  <label htmlFor="otp">Send mobile OTP to {mobileNo} </label>
                </div>
              </div>
              <div className={cx(styles.action)}>
                <button
                  disabled={loading}
                  className="form-input-btn"
                  type="submit"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
      <div className={cx(styles.flexChild)}>
        <Carousel />
      </div>
    </div>
  );
};

export default Validate;
