import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

export const ErrorTooltip = withStyles(() => ({
    tooltip: {
        background: '#fdf1f1',
        borderRadius: '10px',
        fontSize: '8',
        color: '#FF7070'
    }
}))(Tooltip);

export default ErrorTooltip
