import {
  student_id_chk,
  school_name_chk,
  semester_chk,
  high_school_gpa_chk,
  high_school_id_chk,
} from "./field-chk-methods";

export const apHsiSColDefsMap = {
  Student_ID: {
    headerName: "Student_ID",
    cellStyle: (params) => student_id_chk(params),
  },
  SEMESTER: {
    headerName: "SEMESTER",
    cellStyle: (params) => semester_chk(params),
  },
  HIGH_SCHOOL_TGPA: {
    headerName: "HIGH_SCHOOL_TGPA",
    cellStyle: (params) => high_school_gpa_chk(params, 0, 5),
  },
  HIGH_SCHOOL_LGPA: {
    headerName: "HIGH_SCHOOL_LGPA",
    cellStyle: (params) => high_school_gpa_chk(params, 0, 5),
  },
  High_School_ID: {
    headerName: "High_School_ID",
    cellStyle: (params) => high_school_id_chk(params),
  },
  School_Name: {
    headerName: "School_Name",
    cellStyle: (params) => school_name_chk(params),
  },
};
