import React from "react";
import cx from "classnames";
import styles from "./DataGovernance.module.css";
import useDataGovernance from "./useDataGovernance";
import Icon from "../Icon/Icon";
import VerifyFileModal from "../Modal/VerifyFileModal";
import Loader from "../Loader/Loader";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const DataGovernance = () => {
  const {
    showVerifyModal,
    handleVerifyShow,
    handleVerifyClose,
    fileListArr,
    filesData,
    showErrorScreen,
    handleShowError,
    file,
  } = useDataGovernance();

  return (
    <div className={cx(styles.container)}>
      {!showErrorScreen ? (
        <>
          <div className={cx(styles.headerRow)}>
            <span className={cx(styles.headerTitle)}>Verify Files</span>
            <VerifyFileModal
              show={showVerifyModal}
              handleClose={handleVerifyClose}
              data={filesData}
            />
          </div>
          <div className={cx(styles.content)}>
            {fileListArr.map((filesObj) => (
              <div className={cx(styles.card)} key={filesObj.id}>
                <div className={cx(styles.action)}>
                  <span className={cx(styles.cardTitleLbl)}>
                    {filesObj.name}
                  </span>
                  <button
                    className={cx(styles.submitBtn)}
                    onClick={() => handleVerifyShow(filesObj)}
                  >
                    Verify
                  </button>
                </div>
                <div className={cx(styles.files)}>
                  {filesObj.files.map((file, index) => (
                    <div key={file.id}>
                      {index ? <hr className={cx(styles.hrLine)} /> : null}
                      <div className={cx(styles.file)} key={file.id}>
                        <div className={cx(styles.flex)}>
                          <span className={cx(styles.fileLbl)}>
                            {file.name}
                          </span>
                        </div>
                        {file.state === "in_progress" && (
                          <span className={cx(styles.inProgress)}>
                            <Loader />
                          </span>
                        )}
                        {file.state === "accepted" && (
                          <span className={cx(styles.accepted)}>
                            Data Accurate
                          </span>
                        )}
                        {file.state === "noCheck" && (
                          <span className={cx(styles.accepted)}>
                            No Data Available
                          </span>
                        )}
                        {file.state === "error" && (
                          <span className={cx(styles.error)}>
                            <span className={cx(styles.errorLbl)}>
                              Database error:
                            </span>
                            <div
                              className={cx(styles.errorBox)}
                              onClick={() => handleShowError(true, file)}
                            >
                              <Icon name="error_16" />
                              <span>View Data Errors</span>
                            </div>
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className={cx(styles.errContainer)}>
            <span
              className={cx(styles.back)}
              onClick={() => handleShowError(false, {})}
            >
              <Icon name="back" />
            </span>
            <div className={cx(styles.headerTitle)}>
              Data Quality Checker Report: {file.name}
            </div>
            <div
              className="ag-theme-alpine"
              style={{
                height: "450px",
                width: "100%",
                padding: "1rem 0 0 0",
              }}
            >
              <AgGridReact
                columnDefs={file.error.columnDefs}
                rowData={file.error.rowData}
                pagination={false}
                paginationAutoPageSize={false}
                defaultColDef={{
                  editable: false,
                  sortable: true,
                  resizable: true,
                  flex: 1,
                  minWidth: 100,
                }}
              ></AgGridReact>
            </div>
            <div className={cx(styles.flex, styles.legCon)}>
              <div className={cx(styles.flex, styles.legBox)}>
                <div
                  className={cx(styles.legendBox)}
                  style={{ background: "#FF7070" }}
                ></div>
                <div className={cx(styles.legendLabel)}>Missing data value</div>
              </div>
              <div className={cx(styles.flex, styles.legBox)}>
                <div
                  className={cx(styles.legendBox)}
                  style={{ background: "#FFF388" }}
                ></div>
                <div className={cx(styles.legendLabel)}>Incorrect format</div>
              </div>
              <div className={cx(styles.flex, styles.legBox)}>
                <div
                  className={cx(styles.legendBox)}
                  style={{ background: "#A7C0E9" }}
                ></div>
                <div className={cx(styles.legendLabel)}>Inconsistent data</div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DataGovernance;
