import {
  program_name_chk,
  semester_chk,
  student_applicant_id_chk,
  bool_val_chk,
  name_chk,
} from "./field-chk-methods";

export const sdSaiColDefsMap = {
  Student_Applicant_ID: {
    headerName: "Student_Applicant_ID",
    cellStyle: (params) => student_applicant_id_chk(params),
  },
  Applicant_First_Name: {
    headerName: "Applicant_First_Name",
    cellStyle: (params) => name_chk(params),
  },
  Applicant_Last_Name: {
    headerName: "Applicant_Last_Name",
    cellStyle: (params) => name_chk(params),
  },
  SEMESTER_Applied: {
    headerName: "SEMESTER_Applied",
    cellStyle: (params) => semester_chk(params),
  },
  Institution_Accepted: {
    headerName: "Institution_Accepted",
    cellStyle: (params) => bool_val_chk(params),
  },
  Applicant_Accepted: {
    headerName: "Applicant_Accepted",
    cellStyle: (params) => bool_val_chk(params),
  },
  Intended_Major: {
    headerName: "Intended_Major",
    cellStyle: (params) => program_name_chk(params),
  },
};
